.in-page-menu{
    @media screen and (max-width: 767px){
        top: 0;
    }
    z-index: 100;
}

.in-page-menu__dropdown {
    position: absolute;
    z-index: 1;
}

.in-page-menu__btn{
    @media screen and (max-width: 767px){
        width: 100%;
    }
    @media screen and (min-width: 768px){
        min-width: calc(260rem / 16);
    }
}

.in-page-menu__second-level{
    @media screen and (max-width: 767px){
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
    }

    @media screen and (min-width: 768px){
        position: absolute;
        top: 0;
        bottom: 0;
        left: 100%;
        right: -100%;
    }
}

.in-page-menu__touch-only{
    @media screen and (pointer: coarse) {
        display:block;
    }
    display:none;
}

.in-page-menu__btn.collapsed .in-page-menu__icon{
    transform: rotate(180deg);
}

.in-page-menu__btn .in-page-menu__icon{
    transform: rotate(0);
}

.in-page-menu__icon{
    font-size: 7px;
}

.in-page-menu__first-level{
    line-height: 1;
}

.in-page-menu__fz-mobile{
    @media screen and (max-width: 767px){
        font-size: calc(14rem / 16);
    }
}

.in-page-menu__close-second-level{
    @media screen and (max-width: 767px){
        font-size: calc(12rem / 16);
    }
}

.in-page-menu__close-second-level .icon{
    font-size: calc(8rem / 16);
    margin-right: calc(7rem / 16);
}
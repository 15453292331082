.bg-gradient{
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 25%, rgba(255,255,255,1) 100%);

    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}

.customer-rating{
    margin-bottom: calc(45rem / 16);
    @media screen and (max-width: 767px){
        margin-bottom: calc(16rem / 16);
    }
}

.customer-rating__text{
    @media screen and (min-width: 768px){
        max-width: 100%;
        margin: 0 auto;

        border-bottom: 1px solid var(--color-grey);
    }

    @media screen and (max-width: 767px){
        border: 1px solid var(--color-grey);
    }
}

.customer-rating__customer{
    position: relative;
    padding-bottom: 0.5rem;
    padding-left: 3.5rem;
}
.customer-rating__customer:after{
    position: absolute;
    content: '';
    top: -1px;
    left: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    background-image: url('/static/img/ortovox/speech-bubble.svg');
    background-size: 1.5rem;
}

.customer-rating__container{
    max-height: 550px
}


.customer-rating__container::-webkit-scrollbar {
    width: 5px;
}

.customer-rating__container::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: white;
}

.customer-rating__container::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #a5acaf;
}

.product-rating{
    line-height: 1;

    @media screen and (min-width: 768px){
        margin-bottom: calc(50rem / 16);
    }
}

.pds__addon-slider .product-rating{
    margin-bottom: 0;
}

.pds__addon-slider .slick-track{
    margin-left: 0 !important;
    display: flex !important;
}

.pds__addon-slider .slick-slide{
    height: inherit !important;
}

.pds-video-teaser{
    @media screen and (min-width: 768px){
        margin-left: 88px;
    }
}

.pds-small-text{
    font-size: calc(14rem / 16) !important;
}

.pds-store-availability-spacing{
    margin-top: calc(32rem / 16);

    @media screen and (max-width: 767px){
        margin-top: calc(26rem / 16);
    }
}

.pds__product-slider-thumb .pds__product-slider-thumb-image{
    border-color: #E4E6E7;
}
.pds__product-slider-main--highlights{
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
}

.pds-slider-main__btns.slick-prev{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: initial;

    @media screen and (max-width: 767px){
        left: calc(-36rem / 16);
    }
}

.pds-slider-main__btns.slick-next{
    position: absolute;
    bottom: 0;
    top: 0;
    left: initial;
    right: 0;

    @media screen and (max-width: 767px){
        right: calc(-36rem / 16);
    }
}

.pds-slider-main__btns,
.pds-slider-main__btns:hover,
.pds-slider-main__btns:focus,
.pds-slider-main__btns:active{
    border: none;
}

.lg-actions .lg-next, .lg-actions .lg-prev{
    background-color: transparent;
    color: var(--color-white);
}

.pds-slider-small__btns{
    font-size: 10px;
}

.pds-slider-small__btns.slick-prev{
    position: absolute;
    top: 0;
    bottom: initial;
    left: 0;
    right: 0;
    transform: translateY(-100%);
    width: 100% !important;
}

.pds-slider-small__btns.slick-next{
    position: absolute;
    bottom: 0;
    top: initial;
    left: 0;
    right: 0;
    transform: translateY(100%);
    width: 100% !important;
}

.additional-info__button:hover{
    text-decoration: none;
}
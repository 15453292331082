.popover {
    border: none;
}
.popover-header{
    background-color: transparent;
    border: none;
    color: var(--color-primary);
    text-transform: uppercase;
    font-size: .9em;
    font-family: var(--font-default-bold);
}

.popover-body{
    padding-top: 0;
    font-size: .85em;
}

.form-group {
    position: relative;
    margin-bottom: 2rem;
}
.form-control {
    border-color:var(--color-middle-grey);
}
.form-divider {
    margin:2rem 0;
}

.form-info-text {
    white-space: nowrap;
    font-size: 10px;
}

.form-group ~ .form-info-text {
    display: block;
    margin-top: -1.5rem;
}

/* invertiert */
.form--inverted .form-control {
    color:#fff;
    font-family: var(--font-default-bold);
    background: transparent;
    border:1px solid #fff;
}
.form--inverted .floating-label,
.form--inverted .form-group,
.form--inverted .form-info-text{
    color:#fff;
    font-family: var(--font-default-bold);
}
.form--inverted .form-control option{
    color:var(--color-text-default);
}

.floating-label{
    white-space: nowrap;
}

.form-control--icon ~ .form-control-icon{
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
}
.form-control--icon{
    padding-left: 30px !important;
}
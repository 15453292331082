.bg-productinfo{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bg-productinfo--new-product{
    background-color: var(--color-primary);
    color: #fff;
}
.bg-productinfo--new-color{
    background-color: var(--color-primary);
    color: #fff;
}

.teaser-615__background{
    position: relative;
    color: #fff;
}

.teaser-615__background:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50vw;
    transform: translateX(-100%)
}

.teaser-615__background--blue{
    background-color: var(--color-primary-transparency);
}

.teaser-615__background--blue:after{
    background-color: var(--color-primary-transparency);
}

.teaser-615__background--primary{
    background-color: var(--color-primary-transparency);
}

.teaser-615__background--primary:after{
    background-color: var(--color-primary-transparency);
}

.teaser-615__background--grey{
    background-color: var(--color-grey-transparency);
}

.teaser-615__background--grey:after{
    background-color: var(--color-grey-transparency) !important;
}

.teaser-615__smallimg{
    background-size: auto 100%;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
}

.teaser-615__background-right{
    background: transparent;
}
.teaser-615__background-right:after{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50vw;
    background-color: var(--color-primary-transparency);
    transform: translateX(100%)
}

.teaser-615__video{
    position: absolute;
    bottom: 0;
    right: 0;
}
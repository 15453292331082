.nav-tabs {
    border:0;
}
.nav-tabs .nav-item {
    margin-bottom: 0;
}
.nav-tabs .nav-item .nav-link {
    color: var(--color-primary);
    background: #fff;
    border:0;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #fff;
    background: var(--color-primary);
    border:0;
}

.nav-tabs--address-types {
    margin-bottom: 1.5rem;
}
/* adressen */
@media screen and (min-width: 1440px) {
    .nav-tabs--address-types {
        margin-top: .375rem;
        margin-bottom: 2rem;
    }
}

.nav-tabs--line .nav-item{
    color: var(--color-primary);
    background: transparent;
}
.nav-tabs--line .nav-link{
    display: flex;
    flex-direction: column;
}
.nav-tabs--line .nav-item >  a{
    text-align: center;
    text-transform: uppercase;
}
.nav-tabs--line .nav-item > .active{
    color: var(--color-primary);
    background: transparent;
    font-family: var(--font-default-bold);
}
.nav-tabs--line .nav-item > a:after {
    content: "";
    width: 32px;
    height: 2px;
    bottom: -3px;
    position: relative;
    display: inline-block;
    background-color: var(--color-primary);
    opacity: 0;
    transition-duration: .33s;
    transition-property: opacity;
    left: calc(50% - 16px);
}
.nav-tabs--line .nav-item > a.active:after {
    opacity: 1;
}
.nav-tabs--line .nav-item > a:hover:after {
    opacity: 1;
}
@media screen and (max-width: 767px) {
    .nav-tabs-scroll-container {
        position: relative;
        overflow: hidden;
        margin-top: -1.5rem;
        padding-top: 1.5rem;
        margin-bottom: -1rem;
        padding-bottom: 1rem;
        z-index: 1;
    }
    .nav-tabs--scrollable {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        flex-wrap: nowrap;
        margin-top: -1.5rem;
        padding-top: 1.5rem;
        margin-bottom: -1.5rem;
        padding-bottom: 1.5rem;
    }

    .nav-tabs--scrollable > .nav-item {
        flex: 1 0 auto;
    }

    .nav-tabs--scrollable.is-scrollable {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        &::-webkit-scrollbar {
            display: none;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 6rem;
            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .4) 20%, rgba(255, 255, 255, 1) 100%);
            pointer-events: none;
            transition: opacity 300ms ease, transform 300ms ease;
            opacity: 1;
            transform: translateX(0);
        }

        &.is-at-scroll-end:after {
            opacity: 0;
            transform: translateX(100%);
        }
    }
}

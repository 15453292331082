.wysiwyg table,
    table{
    width: 100%;
    font-family: var(--font-default);

    thead > tr > th,
    tbody > tr > th,
    tfoot > tr > th {
        font-family: var(--font-default-heading-medium);
    }
    thead{
        background-color: var(--color-primary);
        color: #fff;
    }
    thead tr:first-child th:first-child {
        border-radius: calc(6rem/16) 0 0 0;
    }
    thead tr:first-child th:last-child{
        border-radius: 0 calc(6rem/16) 0 0;
    }
}

.wysiwyg h1, .wysiwyg h2, .wysiwyg h3, .wysiwyg h4, .wysiwyg h5, .wysiwyg h6{
    color: var(--color-primary);
}
.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: 2em;
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img:not(.ratio-item) {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a {
    color: var(--color-primary);
}
.wysiwyg a:hover,
.wysiwyg a:focus,
.wysiwyg a:active {
    color: var(--color-primary);
    text-decoration: underline;
}

/* lists */
.wysiwyg ol,
.wysiwyg ul{
    list-style: none;
    padding: 0;
}
.wysiwyg ol>li,
.wysiwyg ul>li{
    padding-left: 1.5rem;
    position: relative;
    margin-bottom:.375rem;
}

.wysiwyg ul>li:before{
    content: '';
    width: .25rem;
    height: .25rem;
    border-radius: 50%;
    position: absolute;
    left:.125rem;
    top:.625rem;
    background: var(--color-primary);
}

.wysiwyg ol{
    counter-reset: my-counter;
}
.wysiwyg ol>li:before {
    position: absolute;
    left:0;
    top:0;
    content: counter(my-counter);
    counter-increment: my-counter;
}

.wysiwyg ul.list-with-checkmarks>li:before {
    font-family: iconfont;
    content: var(--icon-check);
    color: #84BE57;
    font-size: .625rem;
    top:.5rem;
    background: transparent;
}

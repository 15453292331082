.table--checkout{
    @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
    }
}

.table--checkout thead{
    background-color: transparent;
    color: #000;
    font-size: .8em;
    @media screen and (max-width: 767px) {
        display: none;
    }
}
.table--checkout th{
    border-top: 0;
}

.table--checkout td {
    border-top: 1px solid #fff;
    vertical-align: middle;
    @media screen and (max-width: 767px) {
        display: block;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
}

.table--checkout tbody {
    border-bottom: 1px solid #fff;
}

.table--checkout tr {

    @media screen and (max-width: 767px) {
        display: block;
        width: 100%;
    }
}

.table--checkout tr td + td {
    @media screen and (max-width: 767px) {
        display: none;
    }
}


.table--compare{
    table-layout: fixed;
}

.table--compare th, .table--compare td{
    border: 2px solid var(--color-light-grey);
}

.table--compare th{
    color: var(--color-primary);
    background-color: var(--color-grey);
    vertical-align: top;
    text-align: center;
    padding: .75em;
    width: 20%;
}

.table--compare__disabled{
    vertical-align: center;
}

.table--compare td{
    text-align: center;
    width: 27%;
    min-width: 27%;
    max-width: 27%;
}

.card-body table thead tr th div{
    word-break: initial;
}

.table__price {
    font-family: var(--font-default-heading-medium);
    font-weight: 400;
    font-size: calc(18rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(23rem/16);
    }
}
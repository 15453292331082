.pagination .page-link{
    background-color: transparent;
    border: none;
    padding: .5em;
    color: var(--color-dark-grey);
    font-family: var(--font-default-heading-medium);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
}

.pagination .active .page-link{
    background-color: transparent;
    color: var(--color-primary);
}
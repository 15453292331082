/*Base-Styling for HTML-Elements*/
a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
}
th {
    font-family: var(--font-default-bold);
    font-weight: normal;
}
textarea {
    resize: vertical;
}

label {
    font-weight: normal;
    margin-bottom: 0;
}
ul,
ol,
dl {
    margin-bottom: 0;
}
dt {
    font-weight: normal;
}

*::-moz-selection {
    color: #ffffff;
    background-color:  var(--color-primary);
}
*::selection {
    color: #ffffff;
    background-color: var(--color-primary);
}
video {
    background: #000000;
}

.container{
    @media screen and (min-width: 1250px) and (max-width: 1450px){
        max-width: 1200px !important;
    }
}
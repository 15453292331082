.floating-label {
    max-width: calc(100% - 0.75rem);
    overflow: hidden;
    position: absolute;
    left: calc(12rem / 16);
    top: 50%;
    transform: translateY(-50%);
    transition: transform 200ms ease-in-out;
    transform-origin: left top;
    pointer-events: none;
    z-index: 1;
    color: #A5ACAF;
}

textarea.form-control{
    position: relative;
    top: 0;
    transform: translate(0);
}
textarea ~ .floating-label{
    pointer-events: none;
    position: absolute;
    transform: translate3d(0, 0, 0) scale(1);
    transform-origin: left top;
    transition: 100ms;
    top: 0;
}

textarea.form-control.has-value ~ .floating-label,
textarea.form-control:focus ~ .floating-label{
    transform: translateY(-75%) scale(.65) translateX(calc(-15rem / 16));
}

select~.floating-label,
.form-control:focus ~ .floating-label,
.form-control.has-value ~ .floating-label {
    transform: translateY(-200%) scale(.65) translateX(calc(-15rem / 16));
    overflow: visible;
}

.form-control:-webkit-autofill ~ .form-control-label,
.form-control:-webkit-autofill:focus ~ .form-control-label {
    transform: translateY(-200%) scale(.65) translateX(calc(-15rem / 16));
}

.select-custom, .select-custom select {
    position: relative;
}
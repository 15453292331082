.half-image-teaser__text{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    
    margin: calc(48rem / 16);
    @media screen and (max-width: 767px){
        margin: calc(34rem / 16) calc(32rem / 16);
    }
}

.half-image-teaser__text-heading{
    max-width: calc(250rem / 16);
}

.half-image-teaser__text-btn{
    margin-top: calc(24rem / 16);
    @media screen and (max-width: 767px){
        margin-top: calc(16rem / 16);
    }
}
.additional-grid-item {
    position: relative;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 454px;
    @media screen and (max-width: 767px) {
        min-height:280px;
    }
}
.additional-grid-item__body {
    position: absolute;
    left:1.5rem;
    right:1.5rem;
    bottom:1.5rem;
    color:#fff;
}
.additional-grid-item__headline {
    font-family: var(--font-default-heading);
    font-size:1.5rem;
    color:#fff;
    text-transform: uppercase;
    margin-bottom:.5rem;
}
.product-grid__select{
    background-color: transparent;
    border: none;
    box-shadow: none;
}

.product-grid__select{
    text-align-last: right;
    text-align: right;
    -ms-text-align-last: center;
    -moz-text-align-last: center;
}

.custom-select {
    background-color: transparent;
    border: 1px solid var(--color-middle-grey);
    box-shadow: none;
}

.custom-select.custom-select--noicon{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: none;
    padding: .375rem .75rem .375rem .75rem;
}
.custom-select.custom-select--noicon::-ms-expand{
    display: none;
}

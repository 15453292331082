.teaser-grid__text{
    max-height: 100%;
    overflow: hidden;
    padding-bottom: 1.5em;
    position: relative;
    width: 100%;
}

.teaser-grid__text:after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1.5em;
    width: 100%;
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 97%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
}

.destroy-slider-mobile .teaser-grid__slider__btns, .destroy-slider-mobile .teaser-grid__slider-btn{
    @media screen and (max-width: 767px){
        display: none !important;
    }
}

.destroy-slider-mobile .teaser-grid__item{
    @media screen and (max-width: 767px){
        margin-top: calc(24rem / 16);
        margin-bottom: calc(24rem / 16);
    }
}

.teaser-grid__text .embed-responsive{
    @media screen and (max-width: 767px){
        overflow: auto;
    }
}
.teaser-grid__text .embed-responsive:before{
    @media screen and (max-width: 767px){
        content: none;
    }
}

.teaser-grid__text .embed-responsive .embed-responsive-item{
    @media screen and (max-width: 767px){
        position: static;
    }
}
.breadcrumb {
    font-size:calc(12rem/16);
    font-family: var(--font-default-bold);
}

.breadcrumb li{
    color: #bebebe
}
.breadcrumb li:last-of-type{
    color: #262626
}
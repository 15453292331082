.media-content-teaser--padding {
    padding: calc(25rem / 16) 0 calc(50rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(100rem / 16) 0;
    }
}
.media-content-teaser__body {
    width: 100%;
    padding: calc(50rem / 16) calc(16rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(20rem / 16) calc(100rem / 16) calc(20rem / 16) calc(50rem / 16);
    }
}
.media-content-teaser__body--left {
    padding: 0 calc(16rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(20rem / 16) calc(50rem / 16) calc(20rem / 16) calc(100rem / 16);
    }
}
.media-content-teaser__btn {
    margin-top: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(25rem / 16);
    }
}
.media-content-teaser__img {
    padding: calc(25rem / 16) calc(16rem / 16) 0;

    @media screen and (min-width: 768px) {
        padding: 0;
    }
}
.media-content-teaser__img-placeholder {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
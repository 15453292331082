.scrollable{
    overflow: auto;
}


.scrollable::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.scrollable::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}
.scrollable::-webkit-scrollbar-thumb {
    background: #a5acaf;
    border: 0px none #ffffff;
    border-radius: 50px;
}
.scrollable::-webkit-scrollbar-thumb:hover {
    background: #a5acaf;
}
.scrollable::-webkit-scrollbar-thumb:active {
    background: #b9acaf;
}
.scrollable::-webkit-scrollbar-track {
    background: #e6e6e6;
    border: 0px none #ffffff;
    border-radius: 51px;
}
.scrollable::-webkit-scrollbar-track:hover {
    background: #e6e6e6;
}
.scrollable::-webkit-scrollbar-track:active {
    background: #e6e6e6;
}
.scrollable::-webkit-scrollbar-corner {
    background: transparent;
}
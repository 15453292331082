.video-js__playbtn:after{
    border-color: transparent transparent transparent var(--color-secondary);
    border-width: 11px 0 11px 22px;
    margin-left: calc(6rem / 16);
}

.video-js__playbtn{
    background-color: var(--color-signature);
    width: 80px;
    height: 80px;
}

.video-js__playbtn.video-js__playbtn--small{
    background-color: var(--color-signature);
    width: 30px;
    height: 30px;
}
.video-js__playbtn.video-js__playbtn--small:after{
    border-color: transparent transparent transparent var(--color-secondary);
    border-width: 5px 0 5px 11px;
    margin-left: calc(3rem / 16);
}

.video-js__playbtn-spacing{
    @media screen and (min-width: 768px){
        margin-top: calc(28rem / 16) !important;
    }
}

.video-js__playbtn:focus{
    background-color:var(--color-secondary);
}
.video-js__playbtn:focus:after{
    border-color: transparent transparent transparent var(--color-signature);
}

.video-js__textcontainer h1,
.video-js__textcontainer .h1,
.video-js__textcontainer h2,
.video-js__textcontainer .h2 {
    color:inherit;
}
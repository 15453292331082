.simple-dropdown {
    position: relative;
    overflow: hidden;
}
.simple-dropdown__btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem .875rem;
    font-family: var(--font-default-bold);
    background: #ECECEC;
    border:0;
}
.simple-dropdown__content{
    color: var(--color-primary);
    background: #ECECEC;
    border:0;
}
.simple-dropdown__content a:last-of-type{
    margin-bottom:1.25rem !important;
}

.simple-dropdown__btn:not(.collapsed) .icon{
    transform: rotate(90deg);
}

footer {
    font-size:.875rem;
    margin-top:5rem;
    font-family: var(--font-light);
    letter-spacing:1px;
    @media screen and (max-width: 767px){
        margin-top:2rem;
    }
}


.footer__logo{
    height: auto;
    width: 45px;
    display: block;
    margin-bottom:2.5rem;

    @media screen and (max-width: 991px){
        display:none;
    }
}

.footer-inline-list{
    position: absolute;
    right: -12px;
    top: 0;
    bottom: 0;
    font-size: 2px;
}

.footer-collapse,
.footer-collapse.in,
.footer-collapse.collapsing {
    @media screen and (min-width: 768px){
        display: block!important;
        height: auto !important;
    }
}

.footer-list:last-child .footer-inline-list{
    display: none !important;
}

.footer-list .footer-inline-list{
    @media screen and (max-width: 767px){
        display: none !important;
    }
}

.collapse-block {
    margin-bottom:2.25rem;
}
.collapse-block nav a:hover,
.collapse-block nav a:focus {
    color:var(--color-signature);
}
footer .collapse {
    @media screen and (min-width: 768px){
        display:block !important;
    }
}
.footer__collapse-hl {
    font-family: var(--font-light);
}
.footer__collapse-hl--lang {
    font-family: var(--font-default-heading);
}
.footer__collapse-hl--collapsing {
    color:var(--color-primary);
    font-family: var(--font-default-heading);
    @media screen and (max-width: 767px){
        text-align:center;
        display:block;
    }
    @media screen and (min-width: 768px){
        pointer-events:none;
    }
}

@media screen and (max-width: 767px){
    .collapse-block--collapsing {
        margin-bottom:.75rem;
    }
    .footer__collapse-hl--collapsing .icon {
        position: relative;
        top:-.25rem;
        margin-left:.375rem;
        font-size:.375rem;
    }
    .footer__collapse-hl--collapsing.collapsed .icon {
        transform: rotate(180deg);
    }
}

.footer-collapse-spacing{
    margin-top: calc(18rem / 16);
}

.lang-selection {
    letter-spacing: 0;
}

.footer-social-icons a{
    position: relative;
    width: 3rem;
    height:3rem;
    color:#fff;
    background: var(--color-primary);
    display: inline-block;
    border-radius: 100%;
    margin-right:.75rem;

    @media screen and (min-width: 992px){
        margin-right:0;
        margin-left:.75rem;
    }
}
.footer-social-icons a .icon{
    position: absolute;
    top:50%;
    left:50%;
    transform: translateY(-50%) translateX(-50%);
    font-size: calc(24rem / 16);
}
.footer-social-icons a .icon-youtube{
    font-size: calc(18rem / 16);
}
.footer-social-icons a:hover,
.footer-social-icons a:focus{
    background: var(--color-signature);
}
.footer-social-icons a:hover .icon,
.footer-social-icons a:focus .icon{
    color: var(--color-primary);
}


.footer-meta {
    font-size:.75rem;
    background-color: #ECECEC;
    color:var(--color-primary);
    font-family:var(--font-light);
    @media screen and (max-width: 767px){
        text-align:center;
        margin-bottom:1rem;
    }
}

.footer-meta a {
    color:var(--color-primary);
}
.footer-meta a:hover,
.footer-meta a:focus {
    color:var(--color-signature);
}



.footer-dealer {
    padding:4rem 0;
    color:var(--color-primary);
    background-color: #ECECEC;
    margin-top:2rem;
    @media screen and (max-width: 767px){
        padding:1.875rem 0;
    }
}
.footer-dealer .container {
    max-width:700px;
}
.footer-dealer .form-control {
    border:0;
}

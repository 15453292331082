.header--checkout {
    height: calc(96rem / 16);
}
.header--checkout .metabar {
    background: #fff;
    border-bottom: calc(1rem / 16) solid var(--color-grey);
}

.header__nav .nav-item, .header__nav .nav-item>a{
    text-align: left;
    align-items: flex-start;

    @media screen and (max-width: 1024px){
        align-items: center;
    }
}
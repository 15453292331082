.pagination .page-link{
    font-size:1rem;
    padding: .75em;
    font-family: var(--font-default-regular);
}

.pagination .page-link:hover,
.pagination .page-link:focus,
.pagination .active .page-link{
    background-color: transparent;
    color: var(--color-signature);
}

.pagination .page-link .icon {
    position: relative;
    top:-.25rem;
}

.pagination .page-link .icon-arrow-right:before {
    font-size: .625rem;
    content:var(--icon-ortovox_arrow-right);
}
.pagination .page-link .icon-arrow-left:before {
    font-size: .625rem;
    content:var(--icon-ortovox_arrow-left);
}
:root {
    --ejb-list-border-color: #dee2e6;
    --ejb-list-head-background-color: var(--color-light-grey);
    --ejb-list-cell-background-color-hover: var(--color-light-grey);
}
.ejb-list {
    width: 100%;
    max-width: 100%;
    background-color: transparent;

    @media screen and (max-width: 767px) {
        display: block;
    }
}
.ejb-list__head {
    @media screen and (max-width: 767px) {
        display: none;
    } @media screen and (min-width: 768px) {
    background-color: var(--ejb-list-head-background-color);
    font-size: .85em;
    font-family: var(--font-default-bold);
}
}
.ejb-list__body {
    @media screen and (max-width: 767px) {
        display: block;
    }
}
.ejb-list__item {
    @media screen and (max-width: 767px) {
        padding-bottom: .75em;
        border-bottom: 1px solid var(--ejb-list-border-color);

        display: grid;
        grid-template-columns: 1fr auto;
        grid-column-gap: .5rem;
        grid-auto-flow: column;
        grid-auto-rows: auto;
    }
}
.ejb-list__item + .ejb-list__item {
    @media screen and (max-width: 767px) {
        margin-top: .75em;
    }
}
.ejb-list__item:hover {
    @media screen and (min-width: 768px) {
        background-color: var(--ejb-list-cell-background-color-hover);
        cursor: pointer;
    }
}
.ejb-list__cell {
    font-family: inherit;
    font-weight: inherit;

    @media screen and (max-width: 767px) {
        display: block;
        grid-column: 1;
    } @media screen and (min-width: 768px) {
    vertical-align: middle;
    width: 1px;
    white-space: nowrap;
    padding: calc(.5vw + .5rem) calc(1.5vw + .5rem);
    border-bottom: 1px solid var(--ejb-list-border-color);
}
}
.ejb-list__cell--main {
    @media screen and (min-width: 768px) {
        width: 100%;
        white-space: normal;
    }
}
.ejb-list__cell--detail {
    @media screen and (max-width: 767px) {
        grid-column: 2;
        grid-row: 1 / 100 ;
        align-self: center;
    }
}
.ejb-list__item-detail-link {
    display: inline-block;
    padding: 1rem;
    margin: -1rem;
    margin-right: 0;
}
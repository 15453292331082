@import "../../../js/libs/@elements/elements-job-bundle/styles/list.css";
@import "../../../js/libs/@elements/elements-job-bundle/styles/list-area.css";

:root {
    --ejb-list-border-color: #dee2e6;
    --ejb-list-head-background-color: #fff;
    --ejb-list-cell-background-color-hover: var(--color-light-grey);
    --ejb-list-ara__form-group-label-color: #A5ACAF;
}
.jobs__apply h3,
.ejb-heading {
    color:var(--color-primary);
}

.ejb-list-area__title {
    margin:0;
    font-size: 1.5rem;
    color:var(--color-primary);
    @media screen and (min-width: 768px) {
        font-size: 2.5rem;
        margin:2rem  0;
    }
}

.ejb-list-ara__filter {
    margin:0 0 2rem 0;
    @media screen and (min-width: 768px) {
        margin:2rem  0;
    }
}

.ejb-list-ara__form-group {
    margin: .5rem 0;
}

.ejb-list-ara__form-group-label {
    font-size: calc(10rem /16);
    color: var(--color-grey);
}

.ejb-list-ara__form-group .form-control {
    height:2.5rem;
}

.ejb-list__head {
    color: #000;
    text-transform: uppercase;
}

.ejb-list__cell {
    @media screen and (min-width: 768px) {
        padding: calc(.5vw + .5rem) calc(1.5vw + .5rem) calc(.5vw + .5rem) 0;
    }
}

tr td.ejb-list__cell--main {
    @media screen and (min-width: 768px) {
        width: 50%;
    }
}

td.ejb-list__cell--detail {
    padding-right: 0;
    text-align: right;
}

td.ejb-list__division {
    @media screen and (min-width: 768px) {
        width: 25%;
    }
}

td.ejb-list__cell--location {
    @media screen and (min-width: 768px) {
        width: 25%;
    }
}

.ejb-list__item-title {
    font-size: calc(16rem / 16);
    padding: 0;
    margin: 0;
}

.ejb-list__item-title-link {
    vertical-align: sub;
}

.ejb-list__item-detail-link {
    font-size: calc(10rem / 16);
}

.li-has-arrow {
    font-size: calc(10rem / 16);
}

.jobs__apply {
    background-color: var(--color-light-grey);
}
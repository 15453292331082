.header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    pointer-events: none;
}

.metabar{
    z-index: 0;
    height: var(--metabar-height);
    pointer-events: auto;
}


.header__nav{
    background-color: white;
    height: var(--header-height);
    z-index: 1;
    pointer-events: auto;
    border-bottom: 1px solid #eee;
    @media screen and (max-width: 1024px) {
        height: var(--header-height-mobile);
    }
}

.header__btn{
    padding: 0.5em;
    margin: 0.125em;
}
@media screen and (max-width: 767px) {
    .header__btn--search > .icon{
        font-size:1.125rem;
    }
}

.header__logo{
    height: 36px;
    width: 160px;
    display: block;
    flex-shrink: 0;
}

#main-nav{
    flex-wrap: nowrap;
    height: 100%;
    @media screen and (max-width: 1024px) {
        position: relative;
        flex-direction: column;
        padding-top: 35px !important; /** because of back button in 2nd layer **/
    }
    @media screen and (min-width: 1025px) {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
}

#primaryNavigation{
    display: flex;
    justify-content: center;
}

.header__nav .nav-item {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px;
    text-align: center;
    @media screen and (max-width: 1024px) {
        height: auto;
        min-height: 64px;
        font-family: var(--font-default-heading);
        padding: 0 15px;
    }

    @media screen and (min-width: 1440px) {
            padding: 0 25px;
    }
}

@media screen and (min-width: 1025px) {
    .header__nav .nav-item--flexwidth-3 {
        flex: 0 0 21%;
        max-width: 21%;
    }
    .header__nav .nav-item--flexwidth-4 {
        flex: 0 0 37%;
        max-width: 37%;
    }
}
.header__nav .nav-item > a{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    color:var(--color-primary);
    letter-spacing: 1px;
    font-family: var(--font-default-heading);
    @media screen and (max-width: 1024px) {
        font-size:1.125rem;
        padding:0 1.5rem;
        position: relative;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }
}

@media screen and (max-width: 1024px) {
    .header__nav .nav-item > a .icon{
        font-size: .75rem;
        margin-left:.5rem;
    }
}


.nav-item.nav-item--decoration > a:after{
    content: '';
    height: 2px;
    width: 100%;
    bottom: -2px;
    position: relative;
    display: inline-block;
    background-color: var(--color-signature);
    opacity: 0;
    transition-duration: .33s;
    transition-property: opacity;
    @media screen and (max-width: 1024px) {
        display: none;
    }
}
.header__nav .nav-item > a:after{
    @media screen and (max-width: 1024px) {
        display: none;
    }
}
.nav-item.nav-item--decoration{
    @media screen and (max-width: 1024px) {
        height: 64px; /* fix for ios safari & chrome */
    }
}
.nav-item.nav-item--decoration a.is-active{
    font-family: var(--font-default-heading);
}
.nav-item.nav-item--decoration a.is-active:after {
    opacity: 1;
}
.nav-item.nav-item--decoration > a{
    text-align: center !important;
    @media screen and (max-width: 1024px) {
        text-align: left;
        text-transform: uppercase;
    }
}

.header__nav .nav-item.nav-item--special>a {
    display: inline-block;
    padding:.25rem .75rem;
    border-radius: 3px;
    background-color: #F7F2EC;

    @media screen and (max-width: 1024px) {
        margin-left: 15px;
        margin-top: 15px;
    }
}
.header__nav .nav-item.nav-item--special>a:hover,
.header__nav .nav-item.nav-item--special>a:focus {
    background-color: #ebe6e0;
}


.header__navItem-text{
    @media screen and (max-width: 1024px) {
        display:block;
    }
    @media screen and (min-width: 1025px) {
        transform: translateY(3px);
        font-size: calc(14rem/16);
    }
}
.header__navItem-text--sub {
    @media screen and (max-width: 1024px) {
        font-size: calc(15rem/16);
        font-family: var(--font-default-heading);
    }
}

.nav-item.nav-item--decoration.is-active > a:after{
    opacity: 1;
}
.nav-item.nav-item--decoration > a:hover:after,
.nav-item.nav-item--decoration > a:focus:after,
.nav-item.nav-item--decoration > a:active:after{
    @media screen and (min-width: 1025px) {
        opacity: 1;
    }
}

.nav-item.nav-item--decoration > a.has-submenu.show:after{
    @media screen and (min-width: 1025px) {
        opacity: 1;
    }
}





.header__searchDropdown.header__dropdown{
    padding-top: 1rem;
    padding-bottom: 0;
    overflow:visible;

    @media screen and (min-width: 768px) {
        height: auto;
        padding-top: 3rem;
        padding-bottom: 4rem;
    }

    @media screen and (max-width: 767px) {
        width:calc(100% + 1px);
    }
}
.submit-btn-append {
    position: absolute;
    right:0;
    top:.75rem;
}

.header__dropdown{
    padding:0;
    background-color: #fff;
    opacity: 0;
    transition-duration: .33s;
    transition-property: opacity;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    display: none;
    overflow:hidden;
    @media screen and (max-width: 1024px) {
        padding: 0;
    }
}
.header__dropdown.show{
    display: flex;
    opacity: 1;
}

.header__backdrop{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    transition-duration: .15s;
    transition-property: opacity;
}

.header__backdrop.fade{
    opacity: 0;
}
.header__backdrop.show{
    opacity: .5;
}

@media screen and (min-width: 1025px) {
    .header__dropdown:not(.header__searchDropdown)>.container {
        padding-top:3.5rem;
        padding-left:5.5rem;
    }
    .header__dropdown.has-bg-img>.container{
        padding:0;
    }
}
.header__submenu .nav-item > a{
    @media screen and (max-width: 767px) {
        font-family: var(--font-default-heading);
    }
}

.nav-item > a.header__dropdown-subheading{
    width: 100%;
    font-family: var(--font-default);
    font-weight: 400;
    color:var(--color-primary) !important;
    @media screen and (min-width: 1025px){
        padding: 0 15px;
        height: auto;
        align-items: flex-start;
        margin-bottom: 1rem;
        font-family: var(--font-default-heading);
        font-weight: 400;
    }
}

.header__navbar{
    height: calc(100% + 1px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    @media screen and (max-width: 1024px){
        position: fixed;
        top: 0;
        right: 0;
        height: calc(100% - var(--header-height-mobile));
        width: 65vw;
        min-width: 320px;
        background-color: #fff;
        z-index: 1001;
        transform: translateX(110%);
        transition-duration: .33s;
        transition-property: transform, opacity;
        margin-top: 75px;
        color: var(--color-primary);
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        visibility: hidden;
        display: none;
    }
    @media screen and (max-width: 425px) {
        width: 93vw;
    }
}

.header__navbar.show{
    @media screen and (max-width: 1024px) {
        display: block;
        visibility: visible;
        opacity: 1;
        transform: translate(0)
    }
}



.header__navbar-mobile-meta{
    width: 100%;
    border-top: 1px solid var(--color-grey);
    justify-content: center;
    align-items: center;
    padding: 1rem;
}


.header__submenu{
    width: 100%;
    @media screen and (max-width: 1024px) {
        z-index: 1;
        background-color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        transition-duration: .33s;
        transition-property: transform;
        transform: translateX(110%);
        display: none;
        will-change: transform;
        opacity: 1;
    }
}
@media screen and (min-width: 1025px) {
    .header__submenu .nav-item{
        padding:0;
    }
    .header__submenu .nav-item a{
        color: #555555fa;
        padding:0;
    }
    .header__submenu .nav-item a:hover{
        color: #000;
    }
}

.header__cartBtn{
    padding-right: 0;
    margin-right: 0;
}

.header__menu-hyphen{
    height: 2.5rem;
    display: flex;
    padding: 0 0 0 20px;
    justify-content: center;
    align-items: center;
    transform: translateY(1.9375rem);
    @media screen and (max-width: 1024px) {
        display: none;
    }

    @media screen and (min-width: 1440px) {
        padding-left: 50px;
    }
}
.header__menu-hyphen:after{
    content: '';
    width: 1.5px;
    height: 30%;
    background-color: #bebebe;
}

.header__submenu-sub{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
@media screen and (max-width: 1024px) {
    .header__submenu-sub .nav-item{
        min-height: 44px;
    }
}

.header__submenu-sub .nav-item a{
    margin-bottom: 0.375rem;
    word-break: break-word;
    font-family: var(--font-light);
    font-size:.875rem;
    @media screen and (max-width: 1024px) {
        min-height: 44px;
        margin-bottom: 0;
        font-size:.9375rem;
    }
}

.header__submenu.show{
    @media screen and (max-width: 1024px) {
        display: block !important;
        transform: translate(0);
    }
}

.header__submenu .nav-item{
    text-align: left ;
    height: auto;
    @media screen and (min-width: 1025px){
        margin-bottom: 0.5em;
    }
}


.header__submenu-heading{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 15px;
}
.header__submenu-heading-back{
    transform: translateY(10px);
    z-index: 1;
    height: 35px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-family: var(--font-light);
    color: var(--color-default);
    font-size:.875rem;
    padding:0 1.5rem;
    @media screen and (min-width: 1025px){
        display:none;
    }
}
.header__submenu-heading-back .icon{
    position: relative;
    top: -.125rem;
    margin-right: 1em;
    font-size: .75rem;
}
.header__submenu-heading-link{
    position: relative;
    min-height: 64px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    text-transform: uppercase;
    font-size:1.125rem;
    padding:0 1.5rem;
    margin: 1.125rem 0;
    @media screen and (max-width: 1024px){
        margin-bottom:0;
    }
}
.header__submenu-heading-category{
    display: block;
    font-size: 10px;
}
@media screen and (min-width: 1025px){
    .header__image {
        position: relative;
        width: 100%;
        top:-3.5rem;
        right:-18px;
        margin-bottom:-4rem;
    }
}

.header__image-textoverlay{
    position: absolute;
    bottom: 1.25rem;
    left: 1.5rem;
    text-align: left;
    max-width:12rem;
    letter-spacing: 1.5px;

    @media all and (min-width: 991px) and (max-width: 1024px){
        bottom: 50%;
    }
}
a.header__image-txt,
.header__image-txt {
    color:#fff !important;
    text-transform: uppercase;
    font-family: var(--font-default-heading);
    font-size: 2rem;
    line-height:1.125;
    text-shadow: 0 0 10px rgba(0,0,0,0.5);
    transition: all 0.2s linear;
}
.header__image-txt:hover,
.header__image-txt:focus {
    color:#fff;
}
.header__image-textoverlay:hover .header__image-txt {
    text-shadow: 0 0 10px rgba(0,0,0,0.95);
}

.header__subnav-close{
    position: absolute;
    top: -2rem;
    right: 15px;

    @media screen and (max-width: 767px){
        top: 0px;
        right: 15px;
    }
}

.searchbar-spacing{
    @media screen and (max-width: 767px){
        margin-top:  calc(30rem/16);
        margin-bottom:  calc(24rem/16);
    }
}



.header__opennav .icon{
    width: 26px;
}

.header__opennav:not(.show) .icon-close{
    display: none
}

.header__opennav.show .icon-burger{
    display: none;
}

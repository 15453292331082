.content-block + .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block {
    margin-top: calc(30rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(40rem/16);
    }
}

.content-block.content-block--small + .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block.content-block--small {
    margin-top: 0.5rem;

    @media screen and (min-width: 768px) {
        margin-top: 0.5rem;
    }
}
.content-block--reduce-margin-bottom {
    margin-bottom: calc(-30rem/16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(-40rem/16);
    }
}
.cart-container{
    position: relative;
}

.cart__image{
    height: 100px;
    width: 100px;
    flex-shrink: 0;
    @media screen and (max-width: 767px) {
        width: 66px;
        height: 66px;
    }
}

.cart__delete-mobile{
    position: absolute;
    top: 0;
    right: 0;
}

td.cart__number-spinner {
    @media screen and (max-width: 767px) {
        display: flex !important;
        justify-content: space-between;
        border-top: none;
        padding-top: 0;
        padding-left: 74px;
        margin-top: -.75rem;
    }
}

.cart__font-md-strong{
    font-family: var(--font-default-bold);
    @media screen and (min-width: 768px) {
        font-size: 1.2em;
    }
}




/** cart dropdown **/
.cart-dropdown__form{
    display: flex;
    flex-direction: column;
    max-height: calc(95vh - var(--header-height));
    @media screen and (max-width: 767px) {
        max-height: calc(100vh - var(--header-height-mobile));

        max-height: calc(100svh - var(--header-height-mobile));
    }
}

.nav-tabs--radio-style {
    border:0;
    display: inline-block;
}
.nav-tabs--radio-style .nav-item {
    display: inline-block;
}
.nav-tabs.nav-tabs--radio-style .nav-item .nav-link {
    background: transparent;
    color:inherit;
}
.nav-tabs--radio-style .nav-item.show .nav-link, .nav-tabs--radio-style .nav-link, .nav-tabs--radio-style .nav-link.active {
    display: inline-block;
    position: relative;
    color: var(--color-text-default);
    border:0;
    padding:.25rem .25rem .25rem 1.5rem;
    margin-right:1.5rem;
}
.nav-tabs--radio-style .nav-link:focus, .nav-tabs--radio-style .nav-link:hover {
    border:0;
}

.nav-tabs--radio-style .nav-link:before{
    content:'';
    position: absolute;
    left:0;
    top:6px;
    display: inline-block;
    border: 1px solid #c4c6c7;
    background: #ffffff;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;

    @media screen and (min-width: 768px) {
        top:6px;
    }
}

.nav-tabs--radio-style .nav-item.show .nav-link:before, .nav-tabs--radio-style .nav-link.active:before {
    border: 1px solid var(--color-primary);
    background: var(--color-primary);
}
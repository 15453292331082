.showStructure main {
    position: relative;
    outline:2px solid green;
    border: 2px solid green;
}
.showStructure main:after {
    content: 'main';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    padding: 2px 5px;
    background: green;
}
.showStructure section {
    position: relative;
    outline:2px solid yellow;
    border: 2px solid yellow;
}
.showStructure section:after {
    content: 'section';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    padding: 2px 5px;
    background: yellow;
}
.showStructure aside {
    position: relative;
    outline:2px solid darkslategrey;
    border: 2px solid darkslategrey;
}
.showStructure aside:after {
    content: 'aside';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    padding: 2px 5px;
    background: darkslategrey;
}
.showStructure .js-toc__title {
    outline:2px solid darkblue;
    border: 2px solid darkblue;
}
.showStructure .sr-only-focusable,
.showStructure .sr-only-focusable {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
}
.showStructure nav {
    position: relative;
    outline:2px solid lightcoral;
    border: 2px solid lightcoral;
}
.showStructure nav:after {
    content: 'nav';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    padding: 2px 5px;
    background: lightcoral;
}

.showStructure h1:before {
    content: '<h1> ';
    color: #24b377;
}
.showStructure h2:before {
    content: '<h2> ';
    color: #24b377;
}
.showStructure h3:before {
    content: '<h3> ';
    color: #24b377;
}
.showStructure h4:before {
    content: '<h4> ';
    color: #24b377;
}
.showStructure h5:before {
    content: '<h5> ';
    color: #24b377;
}
.showStructure h6:before {
    content: '<h6> ';
    color: #24b377;
}
.showStructure h1:after {
    content: '</h1> ';
    color: #24b377;
}
.showStructure h2:after {
    content: '</h2> ';
    color: #24b377;
}
.showStructure h3:after {
    content: '</h3> ';
    color: #24b377;
}
.showStructure h4:after {
    content: '</h4> ';
    color: #24b377;
}
.showStructure h5:after {
    content: '</h5> ';
    color: #24b377;
}
.showStructure h6:after {
    content: '</h6> ';
    color: #24b377;
}

.pimcore_area_wysiwyg,
.pimcore_area_content-text-media {
    position: relative;
    overflow: hidden;
}
.content-block--reduce-margin-bottom.yellow-style,
.content-block.yellow-style {
    position: relative;
    background: var(--color-signature);
}
.content-block--reduce-margin-bottom.yellow-style .text-signature,
.content-block.yellow-style .text-signature {
    color:#fff !important;
}
.content-block--reduce-margin-bottom.yellow-style .btn.btn-outline-primary,
.content-block--reduce-margin-bottom.yellow-style .btn.btn-primary,
.content-block.yellow-style .btn.btn-outline-primary,
.content-block.yellow-style .btn.btn-primary {
    background:#fff !important;
}
.content-block--reduce-margin-bottom.yellow-style:before,
.content-block.yellow-style:before {
    content:'';
    position: absolute;
    left:-50px;
    width:150vw;
    top:-2.5rem;
    bottom:-2.5rem;
    background: var(--color-signature);

    @media screen and (min-width: 768px) {
        left:-500px;
        width:200vw;
        top:-5.375rem;
        bottom:-5.375rem;
    }
}
.embed-responsive-banner:before {
    padding-top: 100%;
    @media screen and (min-width: 768px){
        padding-top: 33.14286%;
    }
}

.embed-responsive-300 {
    &::before {
        padding-top: 15.625%;
        @media screen and (max-width: 767px) {
            padding-top: 48%;
        }
    }
}

.embed-responsive-product-thumb{
    &::before {
        padding-top: 130.39216%;
    }
}

.embed-responsive-pds__product{
    &::before {
        padding-top: 142.96875%;
        @media screen and (max-width: 767px) {
            padding-top: 142.4%;
        }
    }
}
.teaser-grid__slider-btn-position{
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
}

.teaser-grid__slider-btn{
    width: 40px;
    height: 40px;
}

.teaser-image-headline{
    font-size: calc(24rem / 16);

    @media screen and (max-width: 767px){
        font-size: calc(20rem / 16);
    }
}
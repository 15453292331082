.content-block--reduce-margin-bottom.content-block--reduce-margin-bottom.yellow-style,
.content-block {
    margin-top: calc(40rem/16);
    margin-bottom: calc(40rem/16);
    @media screen and (min-width: 768px) {
        margin-top: calc(85rem/16);
        margin-bottom: calc(85rem/16);
    }
}

.pimcore_area_headline + .pimcore_area_content .content-block {
    margin-top: calc(8rem/16);
    @media screen and (min-width: 768px) {
        margin-top: calc(24rem/16);
    }
}

.pimcore_area_headline {
    margin-top: calc(40rem/16);
    @media screen and (min-width: 768px) {
        margin-top: calc(85rem/16);
    }
}

.content-block--small {
    margin-top: calc(20rem/16);
    margin-bottom: calc(20rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(30rem/16);
        margin-bottom: calc(30rem/16);
    }
}

.content-block--reduce-margin-bottom:not(.yellow-style) {
    margin-bottom: calc(-40rem/16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(-85rem/16);
    }
}

.content-block + .content-block--reduce-margin-bottom:not(.yellow-style),
.pimcore_area_content + .pimcore_area_content > .content-block--reduce-margin-bottom:not(.yellow-style) {
    margin-bottom: calc(-30rem/16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(-40rem/16);
    }
}
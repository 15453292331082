.lang-modal__languages a.is-active {
    font-family: var(--font-default);
    font-weight: 400;
    color: #ffcc00;
}
.lang-modal__btn.lang-modal__btn {
    font-family: var(--font-default);
}
.lang-modal__btn.highlight {
    color: #ffcc00;
}
.teaser-slider-spacing{
    padding-bottom: calc(80rem / 16);
}

.content-slider .slick-dots{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateY(150%);
}

.content-slider.slick-slider li button{
    visibility: visible;
    font-size: 0;
    background-color: var(--color-grey);
    border: 0;
    padding: 0;
    width: 8px;
    height: 8px;
    margin: 0 calc(4rem / 16);
}

.content-slider.slick-slider li.slick-active button{
    background-color: var(--color-signature);
}

.content-slider.slick-slider .slick-prev{
    @media screen and (max-width: 768px){
        left: -6px;
    }
}

.content-slider.slick-slider .slick-next{
    @media screen and (max-width: 767px){
        right: -6px;
    }
}

.content-slider.slick-slider{
    @media screen and (min-width: 768px){
        padding-left: calc(32rem / 16);
        padding-right: calc(32rem / 16);
    }
}

.content-slider .slider-btn{
    font-size: calc(14rem / 16);
    color: var(--color-grey);
}

.slider-headline{
    font-size: calc(24rem / 16);
}
.custom-radio__box:before{
    transform: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    content: "";
    position: absolute;

    background: var(--color-signature);
    border: 3px solid white;
    border-radius: 50%;
}

.custom-radio__input:checked~.custom-radio__box{
    border: 1px solid var(--color-primary);
}
.dealer-map__map-container{
    height: calc(100vh - var(--header-height));
    z-index: 100;

    @media screen and (min-width: 768px){
        flex: 1 1 0;
        position: relative;
    }

    @media screen and (max-width: 767px){
        position: fixed;
        top: var(--header-height-mobile);
        bottom: 0;
        left: 0;
        right: 0;
        transform: translateX(100%);
        z-index: 15;
        transition: all 250ms ease;
        height: calc(100vh - var(--header-height-mobile));
    }
}

.dealer-map__map-container.is-open{
    transform: translateX(0);
}

.dealer-map__top-bar{
    @media screen and (max-width: 767px){
        padding: 13px 15px;
        background-color: var(--color-light-grey);
        z-index: 1;
        position: relative;
        font-family: var(--font-default-heading-medium);
    }
}

.dealer-map__close .icon{
    font-size: calc(13rem/16);
    margin-right: calc(5rem/16);
}

.dealer-map__canvas{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.dealer-map__list__hl{
    letter-spacing: calc(6rem/16);
}

.dealer-list li{
    border-bottom: 2px solid var(--color-light-grey);
}

.dealer-list-item__title{
    font-family: var(--font-default-heading-medium);
    text-transform: uppercase;
    letter-spacing: calc(0.6rem/16);
}

.dealer-list-item__head {
    display: block;
    padding: calc(15rem/16) 0;
    text-transform: uppercase;
    font-size: calc(16rem/16);
}

.dealer-list-item__head.collapsed:hover .dealer-list-item__title,
.dealer-list-item__head .dealer-list-item__title{
    color: var(--color-primary);
}

.dealer-list-item__head.collapsed .dealer-list-item__title{
    color: var(--color-default);
}

.dealer-list-item__body .card-body{
    border-radius: 0;
    border: none;
    display: block;
    padding: 15px 5px;
}


.dealer-list-item__body a:not(.link-with-icon):not(.btn){
    text-decoration: underline;
}

.dealer-list-item__body a:not(.link-with-icon):hover{
    color: var(--color-primary);
}

.dealer-list-item__premium{
    color: var(--color-primary);
    font-size: calc(18rem/16);
    margin-top: calc(2rem/16);
}




.store-locator{
    @media screen and (min-width: 768px) {
        max-height: calc(100vh - var(--header-height));
    }
    @media screen and (max-width: 767px) {
        max-height: none;
    }
}

.store-locator > .col-12{
    @media screen and (min-width: 768px) {
        max-height: calc(100vh - var(--header-height));
    }
}


.store-locator__closeMap{
    background: none rgb(255, 255, 255);
    border: 0px;
    margin: 10px;
    padding: 0px;
    position: absolute;
    cursor: pointer;
    user-select: none;
    border-radius: 2px;
    height: 40px;
    width: 40px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    overflow: hidden;
    top: 0px;
    right: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.store-locator__map-container{
    @media screen and (max-width: 767px) {
        height: 1px;
    }
}

.store-locator__container{
    overflow-y: auto;
    height: 100%;
    @media screen and (max-width: 767px) {
        height: auto;
    }
}
.product-grid__form{
    @media screen and (max-width: 767px) {
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        position: fixed;
        transform: translateX(-110%);
        background-color: #fff;
        transition-property: transform;
        z-index: 1001;
        padding-bottom: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.product-grid__form-filter{
    @media screen and (max-width: 767px) {
        overflow-y: auto;
        flex: 1;
    }
}

.product-grid__form.is-open{
    @media screen and (max-width: 767px) {
        transform: translateX(0);
        transition-duration: .33s;
    }
}

.product-grid__form-close{
    position: absolute;
    top: 15px;
    right: 15px;
}

.product-grid__legend {
    font-size: 1em;
    color: var(--color-primary);
    margin-bottom: 0;
    letter-spacing: 0;
    padding-bottom: 0;
    text-align: left;
    width: auto;
    max-width: 95%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.product-grid__filter-collapse{
    padding-left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    @media screen and (max-width: 767px) {
        justify-content: space-between;
    }
}
.product-grid__filter-collapse .icon {
    transition-duration: .33s;
    transform: rotate(-90deg);
    transition-property: transform;
    font-size: .66em;
    @media screen and (max-width: 767px) {
        transform: rotate(0deg);
    }
}
.product-grid__filter-collapse.show .icon {
    transform: rotate(90deg);
}
.product-grid__filter-collapse.show .product-grid__legend-filters{
    display: none;
}

.product-grid__loading{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1001;
}

.product-grid{
    position: relative;
}

.product-grid__sidebar-close{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.product-grid-top-filter .slick-track{
    margin-left: 0 !important;
    display: flex;
}

.product-grid-top-filter .slick-slide{
    display: flex;
    height: auto;
}
.product-grid-top-filter .slick-slide > div {
    flex: 1;
}

#product-grid-top-filter-collapse{
    @media screen and (max-width: 767px) {
        display: block !important;
    }
}

.card--product__header{
    margin: 0 35px;
    margin-right: 40px;
}

.card--product__badge{
    position: absolute;
    top: 0;
    right: 0;
}

.product-grid__category-link{
    word-break: break-word;
}
.product-grid__category-link--active{
    color: var(--color-primary);
    font-family: var(--font-default-bold);
}

.hero-grid--btn{
    margin-top: calc(32rem / 16);
    @media screen and (max-width: 991px){
        margin-top: calc(16rem / 16);
    }
}

.hero-grid{
    @media screen and (min-width: 768px){
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.hero-grid__title {
    margin-top: 0;
    padding-top: 0;
    text-transform: uppercase;
}

.hero-grid__box {
    padding: 1rem;

    @media screen and (max-width: 767px) {
        position: relative;
        padding-bottom: 0;
    }

    @media screen and (min-width: 768px) {
        padding: 2rem;
        background-color: rgba(255, 255, 255, .8);
    }

    @media screen and (min-width: 1200px){
        width: 50%;
    }
}

.hero-grid__embed:before {
    padding-top: 56.25%;
    @media screen and (min-width: 992px){
        padding-top: 25%;
    }
}

.hero-grid__embed-headline {
    position: absolute;
    left: calc(16rem /16);
    bottom: 0;
}
.hero-grid--noimg {
    padding-top: 2rem;
}
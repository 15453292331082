.card{
    border: none;
}

.card.js-go-to-link{
    cursor: pointer;
}

.card--product{
    position: relative;
}

.card--product:after{
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 2px 24px 0 rgba(38,38,38,0.25);
    opacity: 0;
    transition-property: opacity;
    transition-duration: .33s;
    pointer-events: none;
}

.card--product:hover:after{
    opacity: 1;
}

.card--product__varient{
    width: 24px;
    height: 24px;
    border: 1px solid var(--color-grey);
    margin: 0 2px;
}

.card--product__info{
    height: 25px;
}

.card--product-spacer {
    background-repeat: no-repeat;
    background-size: cover;
    @media screen and (max-width: 767px) {
        min-height: 450px;
    }
}

.card--product__btns{
    z-index: 1;
    position: absolute;
    bottom: 0;
    right: 0;
    opacity: 0;
    transition-duration: .33s;
    transition-property: opacity;
    @media screen and (max-width: 991px) {
        opacity: 1;
    }
    @media screen and (any-hover: none) {
        opacity: 1;
    }
}

.card--product:hover .card--product__btns{
    opacity: 1;
}


.card--product__btn{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-grey);
    color: var(--color-dark-grey);
}

.card--product__rating{
    transform: scale(.75)
}

.card--product__img {
    max-width:220px;
}


/* same height cards */
.card--same-height {
    padding-bottom:3.5rem;
    @media screen and (min-width: 768px) {
        height:100%;
    }
}

.card--same-height .card--same-height-btn-wrapper {
    position:relative;
    @media screen and (min-width: 768px) {
        position: absolute;
        left:0;
        right:0;
        bottom:1rem;
    }
}

.card-body .btn .icon.font-size-20 {
    margin-top: -5px;
}
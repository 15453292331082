.raffle__container{
    background-color: #fff;
    transition-property: transform, opacity, background-color;
    box-shadow: 0 2px 24px 0 rgba(38,38,38,0.25);
}

.raffle__container--animating{
    background-color: var(--color-black-60);
}

.raffle__step{
    background-color: var(--color-background-grey)
}

.raffle__tabcontainer{
    background-color: var(--color-primary);
    height: var(--raffle-nav-height);
    display: flex;
    @media screen and (max-width: 767px){
        background-color: var(--color-primary);
    }
}

.raffle__tabs-close{
    background-color: var(--color-black-90);
    border-left: 1px solid #fff;
    color: #fff !important;
    height: var(--raffle-nav-height);
    width: var(--raffle-nav-height);
    display: flex;
    justify-content: center;
    align-items: center;
}

.raffle__tabs-btn{
    color: #fff !important;
    height: var(--raffle-nav-height);
    width: var(--raffle-nav-height);
    display: flex;
    justify-content: center;
    align-items: center;
}

.raffle__tabs-prev{
    border-right: 1px solid #fff;
}
.raffle__tabs-next{
    border-left: 1px solid #fff;
}

.raffle__tabs{
    flex: 1;

}

.raffle__tab-link{
    color: #fff !important;
    text-transform: uppercase;
}

.raffle__tabs:before {
    background: linear-gradient(to right, rgba(135,135,135,1) 0%,rgba(135,135,135,0) 100%);
}

.raffle__tabs:after {
    background: linear-gradient(to right, rgba(135,135,135,0) 0%,rgba(135,135,135,1) 100%);
}

.raffle__tabs-btn{
    opacity: 1;
    visibility: visible;
    transition-duration: .33s;
    transition-property: opacity;
}
.raffle__tabs-btn--remove{
    opacity: 0;
    visibility: hidden;
}
.raffle__tabs-btn--inactive{
    background-color: var(--color-black-60);
}
.raffle__container-wrapper{
    @media screen and (max-width: 767px) {
        background-color: var(--color-background-grey)
    }
}

.raffle__mobile-buttons button{
    border-radius: 0;
}
.js-raffle__mobile-abort{
    margin-right: 1px;
}

.raffle__area-title > p{
    margin: 0;
}
.raffle__content .wysiwyg p{
    font-family: var(--font-serif);
}
.raffle__content{
    background-color: var(--color-light-grey);
}
.raffle__tab-link{
    font-family: var(--font-default-bold);
}
.raffle__tab-link--active{
    color: var(--color-primary) !important;
}
.raffle__tabs-progress{
    background-color: var(--color-signature);
}
.raffle__tabs-close{
    color: var(--color-signature) !important;
}
.raffle__tab-link--progress{
    color: #fff !important;
}
.raffle__content .btn.btn-primary{
    padding: 1rem 1.5625rem;
    text-transform: uppercase;
}

.account-select-box {
    position: relative;
    padding:1.5rem;

    @media screen and (min-width: 992px) {
        height:100%;
        padding:3.5rem;
    }

    @media screen and (max-width: 767px) {
        border-bottom:1px solid var(--color-light-grey);
    }
}
.account-select-box:after {
    @media screen and (min-width: 768px) {
        content:'';
        display:block;
        position:absolute;
        right:0;
        top:8rem;
        bottom:8rem;
        width:1px;
        height:auto;
        background:var(--color-light-grey);
    }
}
.col-md-4:last-of-type .account-select-box:after {
    display: none;
}
.account-select-box__hl {
    font-size:1.375rem;
}

@media screen and (min-width: 1400px) {
    .account-select-box__body {
        min-height:225px;
    }
}

.dealer-support__foot-note {
    margin-left: calc(7rem/16 + 1rem);
}





/* versand */
.payment-methods,
.shipping-methods {
    margin-bottom:2rem;
}
.shipping-methods {
    border-bottom: 1px solid #FFFFFF;
    margin-bottom:2rem;
}
.payment-methods__img,
.shipping-methods__img {
    position: relative;
    text-align:center;
    padding:.875rem;
}
.shipping-methods__img {
    border: 2px solid #a1a1a1;
}
.payment-methods__select-icon,
.shipping-methods__select-icon {
    position: absolute;
    right:.5rem;
    top:.625rem;
    font-size:.75rem;
    color:var(--color-success);
    display: none;
}
.payment-methods__item,
.shipping-methods__item {
    position: relative;
}
.shipping-methods__item {
    position: relative;
    margin-bottom:1rem;

    @media screen and (min-width: 768px) {
        margin-bottom:2rem;
    }
}
.payment-methods__item,
.payment-methods__item--collapse.collapsed:not(.payment-methods__item--selected) {
    border: 2px solid #a1a1a1;
}
.payment-methods__item--selected,
.payment-methods__item--collapse:not(.collapsed) {
    border: 2px solid #0B5F88;
}
.payment-methods__item--collapse:not(.collapsed) .shipping-methods__select-icon,
.payment-methods__item--selected .shipping-methods__select-icon,
.shipping-methods__item--selected .shipping-methods__select-icon {
    display: block;
}
.shipping-methods__item--selected .shipping-methods__img {
    border: 2px solid #0B5F88;
}


.payment-collapse-content {
    padding:1rem;
    border: 2px solid #A1A1A1;
    position: relative;
}

.payment-methods__item--disabled,
.shipping-methods__item--disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: .5;
}


/* payment notify */
.heidelpaySandboxNotify.heidelpaySandboxNotify {
    position: fixed;
    right: .625rem;
    bottom: 3rem;
    padding: 1rem;
    margin: 0;
    width: 360px;
    border-radius: 4px;
}

.unzerChoices.unzerChoices,
.unzerUI.unzerUI {
    font-family: inherit;
}

.unzerUI.input .unzerInput iframe{
    @media screen and (min-width: 768px) and (max-width: 991px) {
        height: 2rem;
    }
}

.unzerUI.input input{
    height: 100% !important;
}

.heidelpayChoices input.heidelpayChoices__input, .unzerUI *{
    box-sizing: border-box !important;
}

.unzerUI.btn-primary{
    color: #fff;
}

.tt-menu.tt-open{
    background-color: white;
    border: 1px solid var(--color-primary);
    border-top:0;
    color: var(--color-primary);
    width: 100%;
    overflow-y: auto;
    max-height: calc(100vh - var(--header-height) - 3rem);
}
.tt-dataset.tt-dataset-product{
    color: var(--color-primary);
}

.tt-selectable.tt-cursor{
    color: var(--color-signature);
}
.typeahead-heading{
    padding-left: 0;
}

a.typeahead-heading__link {
    color:var(--color-signature);
}
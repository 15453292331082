.outtra-cookie-overlay__wrapper-quicklinks .cookie-overlay__btn-container {
    display: block !important;
}

.outtra-cookie-overlay__wrapper-quicklinks .cookie-overlay__text {
    text-align: left;
    padding: 0;
    margin-bottom: 0;
    margin-top: 1rem;
    text-transform: none;
    color: var(--color-middle-grey);
}

.outtra-cookie-overlay__wrapper-quicklinks .cookie-overlay__text:hover {
    cursor: default;
}
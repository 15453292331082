.product-grid-item{
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    border:1px solid #C0BAB8;
}
.product-grid-item:hover,
.product-grid-item:focus {
    box-shadow: 0 0 20px 0 rgba(129,118,114,0.2);
    border-color: #efe9e7;
}


.product-grid-item__image-area{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 767px) {
        flex-direction: column;
        padding-top:1.25rem;
    }
}
.product-grid-item__image-labels{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.product-grid-item__availability-text {
    position: absolute;
    bottom: .125rem;
    left: .75rem;
    background: #ffffff;
    border-radius: 1em;
}

.bg-productinfo--new-product,
.product-grid-item__image-label{
    display: inline-block;
    margin-bottom: .25rem;
    width: auto !important;
    padding: .75rem .5rem !important; /* override p-1 class */
    font-size: 12px;
    background-color:var(--color-signature);
    color: var(--color-primary);
    font-family: var(--font-default-bold);
    border: 1px solid var(--color-signature);
    text-transform: uppercase;
    letter-spacing: 1px;
}
.bg-productinfo--new-product {
    position: absolute;
    top: 0;
    left: 0;
}

.product-grid-item__image-crs{
    position: absolute;
    top: 10px;
    right: 10px;
}
.product-grid-item__variants{
    position: relative;
    padding-left:.75rem;
    @media screen and (max-width: 767px) {
        pointer-events:none;
        padding-left:.5rem;
    }
}

.card--product__varient{
    padding:1px;
    border:1px solid transparent;
    position:relative;
    width: 24px;
    height:24px;
    border-radius:50%;
    display:inline-block;
    overflow:hidden;
    margin-top: calc(4rem / 16);
    margin-left: calc(2rem / 16);
    margin-right: calc(8rem / 16);
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,1);
    @media screen and (max-width: 767px) {
        pointer-events:none;
        display:inline-block;
        width: .5rem;
        height:.5rem;
        margin:0 .125rem 0 0;
    }
}
.card--product__varient:hover:before,
.card--product__varient:focus:before,
.card--product__varient.active:before{
    position: absolute;
    left:0;
    top:0;
    content:'';
    display:block;
    width: 22px;
    height:22px;
    border-radius:50%;
    border:1px solid #fff;
}

.product-grid-item__more {
    font-family: var(--font-default-heading);
    font-size:.75rem;
    position: relative;
    white-space: nowrap;

    @media screen and (min-width: 768px) {
        font-size:1rem;
        top:-.375rem;
        margin-left:.25rem;
    }
}

.product-grid-item__image{
    margin:0 auto;
}
.card--product__img {
    margin-top: 1rem;
    max-width: 250px;
}

.product-grid-item__text{
    padding:0 .5rem .5rem .5rem;
    position: relative;
    flex-grow: 1;
    line-height: 1em;
    color:var(--color-primary);
    font-size: calc(14rem / 16);

    @media screen and (min-width: 768px) {
        padding:0 1rem 1rem 1rem;
        font-size: calc(16rem / 16);
    }
}
.product-grid-item__price-row {
    min-height:60px;
    @media screen and (max-width: 767px) {
        min-height:50px;
    }
}

.product-grid-item__category {
    color: #606060;
    font-size: calc(14rem / 16);
    font-family: var(--font-default-bold);

    @media screen and (max-width: 767px) {
        font-size: calc(12rem / 16);
    }
}

.product-grid-item__sizes{
    max-height: 40px;
    overflow: hidden;
}

.product-grid-item__actions {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: .5rem;
    transition: all 0.2s linear;

    @media (hover: hover) {
        opacity:0;
    }
    @media screen and (max-width: 767px) {
        opacity:1;
    }
}

.product-grid-item__actions .btn.compare-list__btn.compare-list__btn {
    display: block;
    width: 30px !important;
    height:30px !important;
    border-radius: 100%;
    font-size: 1.5rem;
    background-color:var(--color-signature);
    color: var(--color-primary);
    @media screen and (max-width: 767px) {
        width: 24px !important;
        height:24px !important;
        font-size: 1rem;
    }
}
.product-grid-item__actions .btn.compare-list__btn.compare-list__btn:hover {
    background-color:var(--color-signature) !important;
    color: var(--color-primary) !important;
}

.product-grid-item:hover .product-grid-item__actions,
.product-grid-item:focus .product-grid-item__actions {
    opacity: 1;
}

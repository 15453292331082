.load-container{
    z-index: 101;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    transform: translate(-50%, -50%)
}
.load-container--sticky{
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    margin-top: 50px;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        position: absolute !important;
    }
}

.load-container-bg{
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,.6);
}

.loader {
    position: relative;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-gap: 2px;
    width: 100px;
    height: 100px;
}
.loader > div {
    position: relative;
    width: 100%;
    height: 100%;
    background: var(--color-primary);
    transform: scale(0);
    transform-origin: center center;
    animation: loader 2s infinite linear;
}
.loader > div:nth-of-type(1), .loader > div:nth-of-type(5), .loader > div:nth-of-type(9) {
    animation-delay: 0.4s;
}
.loader > div:nth-of-type(4), .loader > div:nth-of-type(8) {
    animation-delay: 0.2s;
}
.loader > div:nth-of-type(2), .loader > div:nth-of-type(6) {
    animation-delay: 0.6s;
}
.loader > div:nth-of-type(3) {
    animation-delay: 0.8s;
}
@keyframes loader {
    0% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
    80% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

.load-container-small{
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
}

.loader-small{
    display: inline-block;
    position: relative;
    width: 26px;
    height: 26px;
}

.loader-small div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 21px;
    height: 21px;
    margin: 2px;
    border: 3px solid #fff;
    border-radius: 50%;
    animation: loader-small 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}
.loader-small div:nth-child(1) {
    animation-delay: -0.45s;
}
.loader-small div:nth-child(2) {
    animation-delay: -0.3s;
}
.loader-small div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes loader-small {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


.loader-success-container{
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: loader-fade 4s cubic-bezier(0.55, 0.085, 0.68, 0.53) forwards;
}

@keyframes loader-fade {
    66%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }

}


.load-container.load-container--bg .loader-ripple{
    display: none !important;
}

.loader-ripple {
    display: inline-block;
    position: relative;
    width: 71px;
    height: 71px;
}
.loader-ripple div {
    position: absolute;
    border: 4px solid var(--color-primary);
    opacity: 1;
    border-radius: 50%;
    animation: loader-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loader-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}
@keyframes loader-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}
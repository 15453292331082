.pds__rating-wrapper .pds__rating-icon {
    margin-left: 5px;
}

.pds__rating-wrapper .pds__rating-icon:first-child {
    margin-left: 0;
}

.pds__rating-bar{
    width: 100%;
    height: 10px;
    background-color: var(--color-middle-grey);
    margin-right: 5px;
}
.pds__rating-bar--active{
    background-color: var(--color-primary);
}

.pds__product-slider{
    transition-duration: .1s;
}

.pds__product-productinfo{
    @media screen and (min-width: 768px) {
        min-width: 160px;
    }
}

.pds__color-image{
    width: 102px;
    height: 106px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pds__color-image:after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: var(--color-light-grey);
    transition-duration: .33s;
    transition-property: background-color;
}
.pds__color-image:hover:after,.pds__color-image:focus:after,.pds__color-image:active:after, .pds__color-image.pds__color-image--active:after{
    background-color: var(--color-primary);
}
.pds__color-image:hover:before,.pds__color-image:focus:before,.pds__color-image:active:before, .pds__color-image.pds__color-image--active:before{
    content: '';
    position: absolute;
    bottom: 4px;
    left: calc(50% - 3px);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 3px 4px 3px;
    border-color: transparent transparent var(--color-primary) transparent;
}

.pds__color-button{
    border: 2px solid transparent;
    position: relative;
    margin-right: 1em;
    height: 28px;
    width: 58px;
    background-clip: padding-box;
}
.pds__color-button:after{
    content: '';
    position: absolute;
    background-color: transparent;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 2px solid #fff;
}
.pds__color-button.pds__color-button--active{
    border-color: #000
}

.pds__color-button:hover, .pds__color-button:active, .pds__color-button:focus{
    background-clip: initial;
}

.pds__product-slider-thumb{
    width: 88px;
    padding-left: .125rem;
    padding-right: .125rem;
}
.pds__product-slider-thumb .slick-list {
    min-height: 100%;
}

.pds__product-slider-thumb-image{
    border: 1px solid transparent;
    cursor: pointer;
}

.pds__product-slider-thumb .slick-current .pds__product-slider-thumb-image{
    border-color: var(--color-primary);
}

.pds__product-slider-thumb-image--video:after{
    content: url('/static/img/icons/play-button.svg');;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.pds__product-slider-main{
    width: 100%;
    @media screen and (min-width: 768px) {
        width: calc(100% - 88px);
        padding: 0 0.75rem;
    }
    @media (min-width: 1200px){
        padding: 0 2.5rem;
    }
    @media (min-width: 1440px){
        padding: 0 5rem;
    }
}


.pds__application-heading{
    color: #fff;
    padding: 0.75em 2em;
    text-align: center;
    width: 100%;
}
.pds__application-heading:hover, .pds__application-heading:active, .pds__application-heading:focus{
    color: #fff !important;
    @media screen and (min-width: 768px) {
        cursor: initial;
    }
}

.pds__application:first-child .pds__application-heading{
    @media screen and (min-width: 768px) {
        border-radius: 100px 0 0 100px;
    }
}
.pds__application:last-child .pds__application-heading{
    @media screen and (min-width: 768px) {
        border-radius: 0 100px 100px 0;
    }
}
.pds__application-collapse{
    @media screen and (min-width: 768px) {
        display: block !important;
        min-height: 100% !important;
    }
}

.pds__highlight-slider-btn{
    text-align: left;
    width: 100%;
    display: flex;
    letter-spacing: 0;
    color: var(--color-middle-grey)
}
.pds__highlight-slider-btn--active{
    background-color: var(--color-primary);
    color: #fff !important;
}

.pds__additonals:hover .pds__additonals-btn{
    opacity: 1;
}

.pds__additonals-btn {
    position: absolute;
    top: 50%;
    left: 10%;
    opacity: 0;
    transition-property: opacity;
    transition-duration: .33s;
    width: 80%;
}

.pds__new-logo.pds__new-logo {
    margin-top:.25rem !important;
    margin-bottom:-.9375rem;
}
.pds__new-logo .btn {
    color:#28a745;
    font-family: var(--font-default);
    letter-spacing:0;
    padding-left:1.5rem;
    position: relative;
}
.pds__new-logo .btn .icon {
    font-size:1.125rem;
    color:#003A60;
    position: absolute;
    left:0;
    top:50%;
    transform: translateY(-50%);
}

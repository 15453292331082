.pds-highlight__spacing .dotmodule__container{
    @media screen and (max-width: 767px){
        padding: calc(20rem / 16);
    }
}
.pimcore_area_content .dotmodule__container{
    @media screen and (max-width: 767px){
        padding: 0;
        margin: 0 -15px;
        width: calc(100% + 30px);
        max-width:none;
    }
}

.dotmodule__btn{
    border-radius: 50%;
    background-color: var(--color-signature);
    border-width: 10px;
    transform: rotate(0) translate(-50%, -50%);
    border-color: var(--color-signature);
}

.dotmodule__btn:hover,
.dotmodule__btn:focus{
    background-color: white;
}

.popover .arrow{
    display: none;
}

.dotmodule__text{
    font-family: var(--font-serif);
}
:root{
    --raffle-nav-height: 40px
}

.raffle__container {
    position: fixed;
    width: 100%;
    height: 100%;
    padding-top: var(--header-height);
    background: #fff;
    z-index: 999;
    top: 0;
    left: 0;
    opacity: 1;
    transform-origin: top left;
    transition-duration: 1s;
    transition-property: transform, opacity;
    @media screen and (max-width: 767px){
        padding-top: var(--header-height-mobile);
    }
}

.raffle__container-wrapper{
    overflow-y: auto;
    height: 100%;
    width: 100%;
    @media screen and (max-width: 767px){
        margin-top: var(--raffle-nav-height);
        /* 90 px = margin-top (--raffle-nav-height) + button height (50px for ortovox) of fixed bottom buttons */
        height: calc(100% - 90px);
    }
}

@keyframes fadeOutRaffle {
    1% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.raffle__container--animating > div{
    opacity: 0;
}

.raffle__start-banner{
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.raffle__content{
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    position: relative;
}
.raffle__content .load-container:before {
    height: 24px;
    width: 24px;
    content: url("/static/img/ortovox/loading.gif");
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.raffle__step{
    flex: 0 0 100%;
    transition-duration: .33s;
    transition-property: transform, opacity;
    opacity: 0;
    transform: translateX(100%)
}

.raffle__step--active{
    opacity: 1;
    transform: translateX(0)
}

.raffle__step--deactivate{
    opacity: 0;
    transform: translateX(-100%);
    position: absolute;
    top: 0;
    left: 0;
}

.raffle__tabs{
    display: flex;
    overflow: hidden;
    flex-wrap: nowrap;
    position: relative;
}

.raffle__tabcontainer{
    position: relative;
    @media screen and (max-width: 767px) {
        position: fixed;
        z-index: 1;
        width: 100%;
        top: var(--header-height-mobile);
    }
}

.raffle__tabcontainer:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    pointer-events: none;
    height: 40px;
    width: 3em;
    background: linear-gradient(to right, var(--color-grey) 0%,rgba(135,135,135,0) 100%);
    transition-duration: .33s;
    transition-property: opacity;
    opacity: 0;
}

.raffle__tabcontainer:after{
    content: '';
    position: absolute;
    top: 0;
    right: calc(var(--raffle-nav-height));
    z-index: 2;
    pointer-events: none;
    height: 40px;
    width: 3em;
    background: linear-gradient(to right, rgba(135,135,135,0) 0%,var(--color-grey) 100%);
    transition-duration: .33s;
    transition-property: opacity;
    opacity: 0;
}
.raffle__tabcontainer--morecontent-left:before, .raffle__tabcontainer--morecontent-right:after{
    opacity: 1;
    @media screen and (max-width: 767px){
        opacity: 0;
    }
}

.raffle__tab{
    flex: 1;
    border-right: 1px solid #fff;
    z-index: 1200;
    @media screen and (max-width: 767px){
        flex: 0 0 100%;
        border-right: none;
    }
}
.raffle__tab:last-child{
    border-right: none;
}

.raffle__tab-link{
    white-space: nowrap;
    height: var(--raffle-nav-height);
    padding: 0 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
}

.raffle__tab-link--clickable{
    cursor: pointer;
}

.raffle__tabs-progress{
    position: absolute;
    top: 0;
    left: 0;
    height: var(--raffle-nav-height);
    background-color: var(--color-primary);
    transition-duration: .33s;
    transition-property: width;
    will-change: width;
}

.raffle__tabs-btn{
    width: 40px;
    height: 40px;
    flex-shrink: 0;
}

.raffle__mobile-buttons{
    display: flex;
    flex-wrap: nowrap;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
}

.raffle__mobile-btn{
    width: 100%;
    padding-left: 0;
    padding-right: 0;
}

.raffle__error-container {
    display: block;
}

/* step content */
.raffle__step-content {
    padding: calc(25rem / 16) calc(16rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(100rem / 16) 0;
    }
}
.raffle__step-text {
    @media screen and (min-width: 768px) {
        margin-top: calc(12rem / 16);
    }
}
.raffle__step-content select.form-control {
    text-align: left;
    -webkit-appearance: none;
    appearance: none;
    background-image: url(/static/img/icons/select-arrow.svg);
    background-size: calc(14rem / 16);
    background-repeat: no-repeat;
    background-position: calc(100% - calc(15rem / 16)) 50%;
}
.raffle__step-content .custom-file {
    height: calc(50rem / 16);
}
.raffle__step-content .custom-file-label,
.raffle__step-content .form-control:not(textarea) {
    height: calc(50rem / 16);
    padding: calc(12rem / 16) calc(15rem / 16) calc(4rem / 16);
}
.raffle__step-content .custom-file__name,
.raffle__step-content .form-control {
    padding: calc(12rem / 16) calc(15rem / 16) calc(4rem / 16);
}
.raffle__step-content .form-control {
    padding: calc(20rem / 16) calc(15rem / 16) calc(4rem / 16);
}
.raffle__step-content .custom-file-label:after {
    height: calc(48rem / 16);
    display: flex;
    align-items: center;
    justify-content: center;
}
.raffle__step-content .floating-label {
    left: calc(15rem / 16);
}
.raffle__step-content .form-control.has-value ~ .floating-label,
.raffle__step-content .form-control:focus ~ .floating-label,
.raffle__step-content select ~ .floating-label {
    transform: translateY(-90%) scale(.65) translateX(0);
}
.raffle__step-content textarea ~.floating-label {
    top: calc(15rem / 16);
}
.raffle__step-content textarea.form-control.has-value ~ .floating-label,
.raffle__step-content textarea.form-control:focus ~ .floating-label {
    transform: translateY(-45%) scale(.65) translateX(0);
}
.raffle__step-content .btn[type="submit"] {
    margin-top: calc(46rem / 16);
}
.raffle__step-content .custom-checkbox .form-errors {display: block;}

@media screen and (max-width: 767px) {
    .raffle__step-content .custom-checkbox__image {
        max-width: calc(148rem / 16);
    }
}

.raffle__content .has-error .custom-radio__input:focus ~ .custom-radio__box {
    border-color: var(--color-danger);
}
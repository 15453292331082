.commerceConnector__map{
    height: 60vh;
    width: 100%;
    min-height: 450px;
    max-height: 80vh;
}

.commerceConnector__close{
    position: absolute;
    top: 0;
    margin: 10px;
    width: 40px;
    right: 0;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.commerceConnector__map .gm-fullscreen-control{
    right: 50px !important;
}

.commerceConnector__container{
    max-height: 80vh;
    height: calc(60vh - 2rem);
    position: relative;
    display: flex;
    flex-direction: column;
}

.commerceConnector__result {
    position: relative;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    flex: 1;
}

.commerceConnector__storeitem{
    border-bottom: 1px solid var(--color-middle-grey)
}
.commerceConnector__storeitem:last-child{
    border-bottom: none;
}
.commerceConnector__storeitem a{
    display: block;
    transition-property: background-color;
    transition-duration: .33s;
}
.commerceConnector__storeitem a:hover{
    background-color: var(--color-light-grey);
}

.commerceConnector__detail{
    height: 100%;
    width: 100%;
}

.commerceConnector__store{
    height: 100%;
    position: relative;
    display: block;
}

.commerceConnector__close-store{
    position: absolute;
    top: 0;
    right: 0;
}
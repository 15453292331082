.dotmodule__btn{
    position: absolute;
    border: 2px solid #fff;
    transform: translate(-50%, -50%) rotate(45deg);
    transform-origin: center;
    width: 28px;
    height: 28px;
    top: 0;
    left: 0;
    transition-property: background-color;
    transition-duration: .33s;
    cursor: pointer;
    outline: 0;
    z-index: 2;
    @media screen and (min-width: 768px) {
        width: 33px;
        height: 33px;
    }
}

.dotmodule__btn:hover, .dotmodule__btn:active, .dotmodule__btn:focus {
    background-color: rgba(255,255,255,0.65);
}

.dotmodule__container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 100%;
    max-width: 100%;
    height: 100%;
    width: 100%;
}

.dotmodule__gradient:before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--color-primary);
    z-index: 1;
    opacity: 0.6;
    top: 0;
    left: 0;
}

.dotmodule__header{
    font-size: 14px !important;
}

.dotmodule__padding{
    margin-bottom: calc(var(--header-height) * -1 )!important;
    padding: 18px;
    @media screen and (max-width: 991px) {
        margin-bottom: calc(var(--header-height-mobile) * -1 )!important;
    }
}

.dotmodule__text{
    font-size: 14px !important;
}
.custom-checkbox:not(.custom-checkbox--color) .custom-checkbox__box:before{
    color: #fff;
    z-index: 1;
}

.custom-checkbox:not(.custom-checkbox--color) .custom-checkbox__input:checked ~ .custom-checkbox__box:after {
    content: '';
    position: absolute;
    background-color: var(--color-primary);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform: none;
}
.custom-checkbox:not(.custom-checkbox--color) .custom-checkbox__input:checked ~ .custom-checkbox__text{
    color: var(--color-primary);
}

.custom-checkbox--color{
    height: 24px;
    width: 24px;
    position: relative;
    margin:.5rem 1rem .5rem 0;
    @media screen and (max-width: 767px) {
        height: 32px;
        width: 32px;
    }
}

.custom-checkbox--color label{
    border-radius: 50%;
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,1);
}

.custom-checkbox--color .icon{
    display: none;
}

.custom-checkbox--color .custom-checkbox--color__active{
    position: absolute;
    left:1px;
    top:1px;
    width: 22px;
    height: 22px;
    border: 1px solid #fff;
    border-radius: 50%;
    opacity: 0;
    @media screen and (max-width: 767px) {
        height: 30px;
        width: 30px;
    }
}
.custom-checkbox--color input:checked ~ .custom-checkbox--color__active{
    opacity: 1;
}

.custom-checkbox__box:before {
    display: none;
}
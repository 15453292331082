.productgrid__category-list {
    font-size: .875rem;
    font-family: var(--font-default-regular);
}
.productgrid__category-list>li {
    margin-top: .375rem;
}
.productgrid__category-title {
    color:var(--color-primary);
    text-transform: uppercase;
    font-size: 1rem;
    margin-bottom: .5rem;
}
.productgrid__category-list a:hover,
.productgrid__category-list a:focus {
    color:var(--color-signature);
}

.product-grid__filter-collapse-target{
    position: relative;
}

button.product-grid__filter-collapse{
    padding-left: 0;
    border:0;
    text-align: left;
}
body{
    font-size: calc(16rem / 16);
    color: var(--color-default);
    @media screen and (max-width: 767px){
        font-size: calc(14rem / 16);
    }
}

form{
    font-family: var(--font-default-heading);
}
.form-group{
    font-size: calc(12rem / 16);
    letter-spacing: calc(2rem / 16);
}

.text-signature{
    color: var(--color-signature) !important;
}
.border-signature{
    border-color: var(--color-signature) !important;
}
.bg-signature{
    background-color: var(--color-signature) !important;
}

h1,h2,h3,h4,h5,h6,
.h1,.h2,.h3,.h4,.h5,.h6, .hero-heading, .subheadline, .slider-headline{
    text-transform: uppercase;
    font-family: var(--font-default-heading);

    padding: initial;
    margin: initial;
    color: var(--color-headline);
}

.hero-heading{
    font-size: calc(60rem / 16);
    @media screen and (max-width: 767px){
        font-size: calc(36rem / 16);
    }
}

.subheadline{
    font-size: calc(16rem / 16);
    @media screen and (max-width: 767px){
        font-size: calc(12rem / 16);
    }
}

h1, .h1{
    font-size: calc(48rem / 16);
    @media screen and (max-width: 767px){
        font-size: calc(30rem / 16);
    }
    letter-spacing: 2px;
}
h2, .h2{
    font-size: calc(40rem / 16);
    @media screen and (max-width: 767px){
        font-size: calc(30rem / 16);
    }
    letter-spacing: 2px;
}
h3, .h3{
    font-size: calc(32rem / 16);
    @media screen and (max-width: 767px){
        font-size: calc(24rem / 16);
    }
    letter-spacing: 2px;
}
h4, .h4{
    font-size: calc(20rem / 16);
    @media screen and (max-width: 767px){
        font-size: calc(16rem / 16);
    }
}
h5, .h5{
    font-size: calc(16rem / 16);
}

.subheadline-spacing{
    letter-spacing: calc(2rem / 16);
}

.font-serif{
    font-family: var(--font-serif);
}

body .font-family-normal{
    font-family: var(--font-default-bold);
}
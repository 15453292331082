.teaser-slider__gradient{
    @media screen and (min-width: 768px){
        position: absolute;
        bottom: 0;
        left: 0;
        height: 24px;
        background: rgb(255,255,255);
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 80%, rgba(255,255,255,1) 100%);
    }
}

.bg-grey.teaser-slider-spacing .teaser-slider__gradient{
    background: rgb(246, 246, 247);
    background: linear-gradient(180deg, rgba(246, 246, 247,0) 0%, rgba(246, 246, 247,1) 80%, rgba(246, 246, 247,1) 100%);
}
.badge--wiggle.is-active{
    opacity: 1;
    animation: 0.5s wiggle ease;
    animation-iteration-count: 1;
}

@keyframes wiggle{
    20%  { transform: translateX(4px);  }
    40%  { transform: translateX(-4px); }
    60%  { transform: translateX(2px);  }
    80%  { transform: translateX(-1px); }
    100% { transform: translateX(0);    }
}
/* parsley + server errors */
.form-errors {
    color: var(--color-danger);
    font-size: 80%;
    display: inline-block;
}
.custom-checkbox + .form-errors,
.custom-radio + .form-errors {
    position: relative;
    right:auto;
    top: auto;
}
.form-errors:empty {
    display: none;
}
.form-errors ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.form-errors ul li:before {
    content:'!';
    padding: .25rem;
    font-family: var(--font-default-bold);
    color:#fff;
    background: var(--color-danger);
    margin-right:.25rem;
    text-transform: uppercase;
}
.form-group.has-error .form-control {
    border-color:var(--color-danger);
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 100% calc(.375em + .1875rem);
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.form-errors.form-error-info-box {
    color: var(--color-danger);
    font-size: 80%;
    border: 1px solid var(--color-danger);
    padding: .375rem .75rem .25rem;
    margin-bottom: 2rem;
    text-transform: uppercase;
    display: none;
}

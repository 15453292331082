
/* Customer Adjustment */
@media screen and (min-width: 768px) and (max-width: 991px) {
    html {
        font-size: calc(16 / (991 / 100) * 1vw);
    }
}

body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: var(--font-size-default);
}

b,
strong,
.strong {
    font-family: var(--font-default-bold);
    font-weight: normal;
}
.small{
    font-size: calc(15rem/16);
}
.smaller{
    font-size: calc(12rem/16);
}
.font-bigger{
    font-size: 1.25em;
}
.font--heading.font--heading{
    font-family: var(--font-default-heading);
}
.font--heading-medium.font--heading-medium{
    font-family: var(--font-default-heading-medium);
}
.font--default{
    font-family: var(--font-default);
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
    color: inherit;
    padding-top: 5px;
}

h1, .h1 {
    font-size: calc(56rem/16);
    font-family: var(--font-default-heading);
    @media screen and (max-width: 767px){
        font-size: calc(32rem/16);
    }
}
h2, .h2 {
    font-size: calc(48rem/16);
    font-family: var(--font-default-heading-medium);
    @media screen and (max-width: 767px){
        font-size: calc(24rem/16);
    }
}
h3, .h3 {
    font-size: calc(32rem/16);
    font-family: var(--font-default-heading-medium);
    @media screen and (max-width: 767px){
        font-size: calc(20rem/16);
    }
}
h4, .h4 {
    font-size: calc(23rem/16);
    font-family: var(--font-default-heading-medium);
    @media screen and (max-width: 767px){
        font-size: calc(18rem/16);
    }
}
h5, .h5 {
    font-size: calc(16rem/16);
    font-family: var(--font-default-heading-medium);
}
h6, .h6 {
    font-size: calc(15rem/16);;
}

.text-dark-grey{
    color: var(--color-dark-grey);
}
.text-grey{
    color: var(--color-middle-grey);
}
.text-shadow{
    text-shadow: 0px 0px 6px rgba(0, 0, 0, .33);
}

.text-spaced{
    letter-spacing: 0.1em;
} 
.text-spaced-0{
    letter-spacing: 0 !important;
}
.icon-in-text{
    vertical-align: -.1em;
}

.js-go-to-link__link.text-primary:hover{
    color: var(--color-primary) !important;
}

.text-overflow-7{
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 7;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
.text-overflow-4{
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
.text-overflow-3{
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.text-overflow-md-7{
    @media screen and (min-width: 768px){
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 7;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }
}
.text-overflow-md-4{
    @media screen and (min-width: 768px){
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 4;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }
}

.text-overflow-md-3{
@media screen and (min-width: 768px){
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
}

.text-overflow-lg-4{
@media screen and (min-width: 992px){
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
}


.text-temp-hot.text-temp-hot {
    color: var(--color-temp-hot)
}
.text-temp-normal.text-temp-normal {
    color: var(--color-temp-normal)
}
.text-temp-cold.text-temp-cold {
    color: var(--color-temp-cold)
}

.link-text{
    text-decoration: underline;
    color: var(--color-primary)
}
.icon-sm{
    font-size: .5em;
}

.text-break-normal{
    word-break: normal;
}
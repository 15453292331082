.bg-productinfo{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bg-productinfo--new-product{
    background-color: var(--productinfo-newproduct);
    color: #fff;
}
.bg-productinfo--new-color{
    background-color: var(--productinfo-newcolor);
    color: #fff;
}
.bg-productinfo--new-product + .h-100{
    height: calc(100% - 30px) !important;
}
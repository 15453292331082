.wishlist__btn.is-active .wishlist__remove,
.wishlist__btn:not(.is-active) .wishlist__add {
    display: block;
}

.wishlist__btn.is-active .wishlist__add,
.wishlist__btn:not(.is-active) .wishlist__remove{
    display: none;
}

.product-grid-item__actions .btn.wishlist__btn {
    font-size: 1.5rem
}


.product-grid-item__actions .wishlist__btn.is-active .icon:before,
.product-grid-item__actions .wishlist__btn:hover .icon:before {
    content: var(--icon-heart-filled);
}

.product-grid-item__actions .icon.icon-heart {
    color: var(--color-signature);
}

/* wishlist item */
.wishlist-item {
    padding: calc(20rem / 16);
    border-radius: calc(4rem / 16);
    background-color: #fff;
    box-shadow: 0 calc(10rem / 16) calc(50rem / 16) 0 rgba(0,58,96,0.1);
}
.wishlist-item__title {
    padding-right: calc(18rem / 16);
    text-transform: uppercase;
    font-weight: var(--font-weight-700);

    & a {
        color: var(--color-primary);
    }

    @media (min-width: 768px) {
        padding-right: 0;
    }
}
.wishlist-item__category{
    & a {
        color: var(--color-primary);
    }
}
.wishlist-item__remove {
    font-size: calc(20rem / 16);
    position: absolute;
    top: calc(18rem / 16);
    right: calc(18rem / 16);
}
.wishlist-item__img-container {
    width: calc(100rem / 16);
    background-color: var(--color-image-background);

    @media (min-width: 768px) {
        width: 100%;
    }
}
.wishlist-item__img {
    overflow: hidden;
}
.wishlist-item__img__img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    height: 100%;
    width: auto;
}

.lg-backdrop{
    background-color: #00000082;
}
.lg-toolbar.lg-group{
    background-color: transparent;
}
.lg-video iframe {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100% !important;
    height: 100% !important;
}
.mx-n6px{
    margin-right: calc(-6rem / 16);
    margin-left: calc(-6rem / 16);
}

.text-transform-none {
    text-transform: none;
}

.line-height-1{
    line-height: 1;
}

.cursor-pointer{
    cursor: pointer;
}

.text-default{
    color: var(--color-default);
}

.newsletter-input{
    min-height: 47px;
}
.newsletter-input ~ .floating-label{
    transform: translateY(-45%);
}

.newsletter-text{
    font-size: calc(11rem / 16);
    margin-top: 16px;

@media screen and (min-width: 1200px){
    margin-top: 32px;
}
}
.pswp {
    --pswp-bg: #fff;
}

.pswp__top-bar,
.pswp__button--arrow {
    @media (min-width: 992px) {
        padding-left: 15px;
        padding-right: 15px;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
        max-width: 960px;
    }

    @media (min-width: 1200px) and (max-width: 1399px) {
        max-width: 1140px;
    }

    @media (min-width: 1400px) {
        max-width: 1400px;
    }
}

.pswp__top-bar {
    top: 0;
    right: 0;

    margin-left: auto;
    margin-right: auto;
}

.pswp__button {
    color: var(--color-primary);
}

.pswp__button .pswp__icn {
    fill: currentColor;
}

.pswp__button--close {
    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 1;

    margin: 0;

    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.pswp__button--close::before {
    content: var(--icon-gonso-close);

    display: block;
    width: 18px;
    height: 18px;

    font-size: 18px;
}

.pswp__button--close .pswp__icn {
    display: none;
}

.pswp__button--arrow {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    width: 100%;
    height: 1px;

    right: 0;
    left: 0;

    margin: auto;

    overflow: visible;
    pointer-events: none !important;
}

.pswp__button--arrow,
.pswp__button--arrow:hover,
.pswp__button--arrow:focus,
.pswp__button--arrow:active {
    padding: 0 12px;

    @media (min-width: 992px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.pswp__button--arrow--next {
    justify-content: flex-end;
}

.pswp__button--arrow .pswp__icn {
    position: static;
    width: 22px;
    height: 22px;

    margin-top: -11px;

    pointer-events: auto;
    cursor: pointer;
}

.full-width-slider__slide {
    /*override inline style*/
    display: block !important;
}

.full-width-slider__embed-responsive{
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}
.full-width-slider__embed-responsive:before{
    content: '';
    display: block;
    padding-top: 45%;
    @media screen and (max-width: 767px) {
        display: none;
    }
}
.full-width-slider__embed-item{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    @media screen and (max-width: 767px) {
        position: relative;
        height: auto;
    }
}
.full-width-slider__image{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    @media screen and (max-width: 767px) {
        position: relative;
        height: auto;
    }
}

.full-width-slider__text-container{
    z-index: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    @media screen and (max-width: 767px) {
        position: absolute;
        top: 0;
        left:0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
}

.full-width-slider__video{
    position: absolute;
    right:0;
    bottom: 0;
}

.full-width-slider__arrow-container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 2;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
.newsletter-banner {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;

    @media screen and (max-width: 991px){
        margin:0 -1rem;
    }
}

.newsletter-banner__body {
    color:var(--color-primary);
    letter-spacing: 2px;
    width:100%;
    max-width:calc(800rem / 16);
    margin:0 auto;
    padding:calc(200rem / 16) 1rem;

    @media screen and (max-width: 767px){
        padding:calc(50rem / 16) 1rem;
        font-size:calc(14rem / 16);
    }
}
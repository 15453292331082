.embed-responsive-cover .embed-responsive-item{
    width: auto;
    min-height: 100%;
    left: 50%;
    transform: translateX(-50%);
}
.embed-responsive-cover{
    height: 100%;
}

.embed-responsive-300 {
    &::before {
        padding-top: 15.625%;
        @media screen and (max-width: 767px) {
            padding-top: 39.0625%;
        }
    }
}

.embed-responsive-350 {
    &::before {
        padding-top: 25.54745%;
        @media screen and (max-width: 575px) {
            padding-top: 56.25%;
        }
    }
}

.embed-responsive-464 {
    &::before {
        padding-top: 33.86861%;
        @media screen and (max-width: 575px) {
            padding-top: 56.25%;
        }
    }
}

.embed-responsive-547 {
    &::before {
        padding-top: 28.48958%;
    }
}

.embed-responsive-597 {
    &::before {
        padding-top: 31.09375%;
    }
}

.embed-responsive-615 {
    &::before {
        padding-top: 32.03125%;
        @media screen and (max-width: 575px) {
            padding-top: 56.25%;
        }
    }
}

.embed-responsive-788 {
    &::before {
         padding-top: 56.28571%;
    }
}

.embed-responsive-header-small{
    &::before {
        padding-top: 42.93785%;
    }
}

.embed-responsive-header-big{
    &::before {
        padding-top: 21.468925%;
    }
}

.embed-responsive-21by9{
    &::before {
        padding-top: 42.85714%;
    }
}

.embed-responsive-4by3{
    &::before {
        padding-top: 75%;
    }
}

.embed-responsive-22by10 {
    &::before {
        padding-top: 44.843%;
    }
}

.embed-responsive-3by4{
    &::before {
        padding-top: 133.33333%;
    }
}

.embed-responsive-3by2{
    &::before {
        padding-top: 66.66667%;
    }
}

.embed-responsive-2by3{
    &::before {
         padding-top: 150%;
     }
}

.embed-responsive-3by1{
    &::before {
        padding-top: 33.33333%;
    }
}

.embed-responsive-banner {
    &::before {
        padding-top: 27.22222%;
    }
}

.embed-responsive-16by9{
    &::before {
        padding-top: 56.25%;
    }
}

.embed-responsive-sm-16by9{
    &::before {
        @media screen and (min-width: 576px) {
            padding-top: 56.25%;
        }
    }
}

.embed-responsive-md-16by9{
    &::before {
        @media screen and (min-width: 768px) {
            padding-top: 56.25%;
        }
    }
}

.embed-responsive-lg-16by9{
    &::before {
        @media screen and (min-width: 992px) {
            padding-top: 56.25%;
        }
    }
}

.embed-responsive-xl-16by9{
    &::before {
        @media screen and (min-width: 1200px) {
            padding-top: 56.25%;
        }
    }
}

.embed-responsive-9by16{
&::before {
     padding-top: 177.78%;
 }
}

.embed-responsive-three-image-vertical{
&::before {
     padding-top: 114.73%;
 }
}

.embed-responsive-three-image-horizontal{
&::before {
     padding-top: 27.78%;
 }
}

.embed-responsive-2to1-small{
&::before {
     padding-top: 51.28%;
 }
}

.embed-responsive-2to1-big{
&::before {
     padding-top: 131.62%;
 }
}

.embed-responsive-top-category-big{
&::before {
     padding-top: 73.55%;
 }
}

.embed-responsive-top-category-small{
&::before {
     padding-top: 118.05%;
 }
}

.embed-responsive-three-image-small{
&::before {
     padding-top: 56.21%;
 }
}

.embed-responsive-1by1{
    &::before {
        padding-top: 100%;
    }
}
.embed-responsive-4by1{
&::before {
     padding-top: 25%;
 }
}

.embed-responsive-15by8{
&::before {
     padding-top: 53.33333%;
 }
}

.embed-responsive-hero-content{
    &::before{
        padding-top: 43.92857%;
    }
}

/* content slide additional responsives with media queries */
.embed-responsive-slide-big {
    &::before {
        padding-top: 33.33333%;
        @media screen and (max-width: 767px) {
            padding-top: 100%;
        }
    }
}
.embed-responsive-slide-small{
    &::before {
        padding-top: 66.66667%;
    }
}
.embed-responsive-slide-small.embed-responsive-slide-small-text{
    &::before {
        @media screen and (max-width: 767px) {
            padding-top: 0;
        }
    }
}

.embed-responsive-slide-small.embed-responsive-slide-small-text .embed-responsive-item{
    @media screen and (max-width: 767px){
        position: relative;
    }
}

    /***
     GRID TEASER  597px
     */

.embed-responsive-grid-teaser-big-75{
    &::before {
        padding-top: 58.2155%;
        @media screen and (max-width: 767px) {
            padding-top: 75%;
        }
    }
}
.embed-responsive-grid-teaser-big-75-break{
    &::before {
        padding-top: 58.2155%;
        @media screen and (max-width: 767px) {
            padding-top: 125%;
        }
    }
}
.embed-responsive-grid-teaser-big-50{
    &::before {
        padding-top: 87.6652%;
        @media screen and (max-width: 767px) {
            padding-top: 75%;
        }
    }
}
.embed-responsive-grid-teaser-big-50-break{
    &::before {
        padding-top: 87.6652%;
        @media screen and (max-width: 767px) {
            padding-top: 125%;
        }
    }
}
.embed-responsive-grid-teaser-big-25{
    &::before {
        padding-top: 177.41456%;
        @media screen and (max-width: 767px) {
            padding-top: 75%;
        }
    }
}
.embed-responsive-grid-teaser-big-25-break{
    &::before {
        padding-top: 177.41456%;
        @media screen and (max-width: 767px) {
            padding-top: 125%;
        }
    }
}


 /**
 GRID TEASER 464
  */


.embed-responsive-grid-teaser-small-75{
    &::before {
        padding-top: 45.24622%;
        @media screen and (max-width: 767px) {
            padding-top: 75%;
        }
    }
}
.embed-responsive-grid-teaser-small-75-break{
    &::before {
        padding-top: 45.24622%;
        @media screen and (max-width: 767px) {
            padding-top: 125%;
        }
    }
}
.embed-responsive-grid-teaser-small-50{
    &::before {
        padding-top: 68.1351%;
        @media screen and (max-width: 767px) {
            padding-top: 75%;
        }
    }
}
.embed-responsive-grid-teaser-small-50-break{
    &::before {
        padding-top: 68.1351%;
        @media screen and (max-width: 767px) {
            padding-top: 125%;
        }
    }
}
.embed-responsive-grid-teaser-small-25{
    &::before {
        padding-top: 137.89004%;
        @media screen and (max-width: 767px) {
            padding-top: 75%;
        }
    }
}
.embed-responsive-grid-teaser-small-25-break{
    &::before {
        padding-top: 137.89004%;
        @media screen and (max-width: 767px) {
            padding-top: 125%;
        }
    }
}

.embed-responsive-md:before {
    @media screen and (max-width: 767px) {
        padding-top: 0 !important;
    }
}.embed-responsive-md .embed-responsive-item{
    @media screen and (max-width: 767px) {
        position: relative;
    }
}

/*mosaic*/
.embed-responsive-mosaic-small{
    &::before {
         padding-top: 43.78613%;
    }
}

.embed-responsive-mosaic-big{
    &::before {
         padding-top: 66.76301%;
     }
}

.embed-responsive-692by231{
    &::before {
        padding-top: 66.76301%;
    }
}

.embed-responsive-auto .embed-responsive-item {
    position: relative;
}

.embed-responsive.embed-responsive-absolute{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    transform-origin: center center;
}

.embed-responsive .embed-responsive-item.js-video_youtube-container {
    /* fix black border in some viewport sizes */
    outline: 2px solid #fff !important;
    outline-offset: -1px;
}

.embed-responsive .embed-responsive-item.js-video_youtube-container img {
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.embed-responsive.embed-responsive-16by9 video {
    background: transparent;
}

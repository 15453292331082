.error-list {
    list-style-type: none;
    padding-left: 0;
}

.error-list__message {
    padding: .5rem;
    text-transform: uppercase;
    color: #dc3545;
}

.account-select-box {
    position: relative;
    padding: 1.5rem;
    text-align: center;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 992px) {
        height: 100%;
        padding: calc(55rem / 16) calc(30rem / 16);
    }

    @media screen and (max-width: 767px) {
        border-bottom: 1px solid var(--color-border-grey);
    }
}

.account-select-box ul {
    text-align: left;
}

.account-select-box:after {
    @media screen and (min-width: 768px) {
        background: var(--color-border-grey);
    }
}

.account-select-box__body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;

    @media screen and (min-width: 1400px) {
        min-height: unset;
    }
}

.account-select-box__hl {
    font-size: calc(19rem / 16);
    line-height: 1.2;

    @media screen and (max-width: 767px) {
        font-size: calc(18rem / 16);
    }
}

.account-select-box--form .account-select-box__body {
    width: calc(290rem / 16);
    max-width: 100%;
    margin: 0 auto;
}

.checkout-overview-data {
    margin-top: calc(32rem / 16);
    margin-bottom: calc(40rem / 16);
}

.checkout-overview-data__collapse-box {
    min-height: calc(200rem / 16);
}

/* shipping/payment */
.payment-methods__img, .shipping-methods__img {
    background: var(--color-background-grey);
    border: 0;
    padding: calc(20rem / 16) calc(15rem / 16);
    transition: background 100ms ease-in-out;

    @media screen and (min-width: 786px) {
        padding: calc(34rem / 16) calc(20rem / 16);
    }
}

.payment-methods__item:hover .payment-methods__img, .shipping-methods__item:hover .shipping-methods__img {
    background: #f1f1f2;
}

.payment-methods__img > img, .shipping-methods__img > img {
    max-height: calc(30rem / 16);
    width: auto;
}

.shipping-methods__item--selected .shipping-methods__img,
.payment-methods__item, .payment-methods__item--collapse.collapsed:not(.payment-methods__item--selected),
.payment-methods__item--collapse:not(.collapsed), .payment-methods__item--selected {
    border: 0;
}

.payment-methods__select-icon, .shipping-methods__select-icon {
    color: var(--color-primary);
    font-size: calc(8rem / 16);
}

.checkout-header__logo__image {
    height: 2.2rem;
}

.checkout-header__sub-logo__additional-text {
    line-height: 1rem;
    font-size: 0.7rem;
}

.checkout-header__sub-logo__image {
    height: 2rem;
}

#paylater-form-payone ul.parsley-errors-list {
    list-style: none;
    padding-left: 15px;
}
.payment-collapse-content {
    border: 0;
}
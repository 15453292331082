.slick-slider > div + div  {
    visibility: hidden;
}
.slick-slider:not(slick-initialized) .slick-track > div + div  {
    visibility: visible;
}

.slick-slider > div {
    display: none;
}
.slick-slider > div:first-child {
    display: block;
}
.slick-slider.slick-initialized > div {
    display: block;
}


.slick-arrow{
    position: absolute;
    top: calc(50% - 2em);
    z-index: 1;
    display: flex !important;
}

.slick-arrow.slick-disabled{
    opacity: 0;
    visibility: hidden;
    display: none !important;
}

.product-grid-top-filter .slick-arrow.slick-disabled{
    opacity: 1;
    visibility: visible;
    display: block !important;
    background-color: var(--color-middle-grey);
    border-color: var(--color-middle-grey);
}

.slick-prev{
    left: 0;
}

.slick-next{
    right: 0;
}

.slick-dots{
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: center;
}

.slick-dots button{
    border-radius: 100%;
    margin: 0 0.25em;
    border: none;
    background-color: var(--color-white);
    width: 30px;
    height: 30px;
}
.slick-dots .slick-active button{
    border: 2px solid var(--color-primary);
    color: var(--color-primary)
}

.product-grid-top-filter{
    @media screen and (min-width: 576px) and (max-width: 768px){
        width: 90%;
        margin: auto;
    }
}

.product-grid__category-link{
    @media screen and (max-width: 575px){
        padding: 3px 6px !important;
        font-size: 12px;
    }
}

.slick-slider> .product-grid-top-filter__btn-prev,
.slick-slider> .product-grid-top-filter__btn-next{
    position: absolute;
    top: 50%;
    visibility: visible;
}

.product-grid-top-filter__btn-prev{
    left: 36px;
    transform: translate(-100%, -50%);
}

.product-grid-top-filter__btn-next{
    right: 36px;
    transform: translate(100%, -50%);
}

.js-product-grid-top-filter{
    @media screen and (max-width: 1438px){
        padding: 0;
    }
}

.product-grid-top-filter .btn.slick-arrow{
    display: block !important;
}

.js-imageSlider .slick-active{
    display: flex;
    justify-items: center;
    align-items: center;
}

.js-imageSlider .slick-active button{
    padding: 0;
}

.slider-container{
    transform: translateY(-7px);
}

.js-teaser-grid__slider-prev,
.js-teaser-grid__slider-next{
    top: 0;
}

.js-imageSlider .slick-slide div{
    width: 100%;
    height: 100%;
}

.pds__product-slider .slick-slider .slick-dots{
    margin-bottom: 15px;
}
.pds__product-slider .slick-slider .slick-dots button{
    visibility: visible;
    border-radius: 100%;
    margin: 0 0.25em;
    margin-right: 8px;
    background-color: var(--color-middle-grey);
    border: none;
    width: 24px;
    height: 24px;
    font-size: 0;
}
.pds__product-slider .slick-slider .slick-dots .slick-active button {
    background-color: var(--color-primary);
}

.slick-slider .slick-slide li{
    visibility: visible;
}

.slick-slider li button{
    visibility: hidden;
}

.js-pds__highlight-slider .slick-track {
    display: flex;
}
.js-pds__highlight-slider .slick-track .slick-slide {
    display: flex;
    height: auto;
}

.js-pds__highlight-slider .slick-track .slick-slide div{
    width: 100%;
}

.slick-slider.slick-slider--align-left .slick-track{
    margin-left: 0 !important;
}

.icon.rotate-left{
    transform: rotate(270deg);
}

.icon.rotate-right{
    transform: rotate(90deg);
}
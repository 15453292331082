.content-team__card{
    position: relative;
    cursor: pointer;
}

.content-team__card-name{
    transition-property: background-color, color, letter-spacing;
    transition-duration: .33s;
    color: var(--color-primary);
    background-color: #fff;
    text-align: center;
    text-transform: uppercase;
}

.content-team__card:hover .content-team__card-name{
    letter-spacing: .1em;
}
 
.content-team__card.is-active .content-team__card-name{
    background-color: var(--color-primary);
    color: #fff;
    letter-spacing: .1em;
}

.content-team__card.is-active:after {
    content: '';
    position: absolute;
    top: 100%;
    left: calc(50% - 10px);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
}

.content-team__card-image{
    filter: grayscale(1);
    transition-duration: .33s;
    transition-property: filter;
}
.content-team__card:hover .content-team__card-image{
    filter: grayscale(0);
}.content-team__card.is-active .content-team__card-image{
    filter: grayscale(0);
}
.content-team__slider .slick-track{
    line-height: 0;
}

.content-team__slider > div + div  {
    position: absolute;
    visibility: hidden;
}
.content-team__slider:not(slick-initialized) .slick-track > div + div  {
    position: relative;
    visibility: visible;
    line-height: 0;
}
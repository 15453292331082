.history-slider__slider{
    position: relative;
}
.history-slider__slider .slick-track{
    padding: 25px 0;
    perspective: 2000px;
}
.history-slider__slider .slick-slide{
    transition-duration: .5s;
    transform: scale(.75);
    transform-origin: 110% 50%;
    transition-property: transform;
    position: relative;
}
.history-slider__slider .slick-slide .js-lazy-img{
    filter: blur(3px);
}

.history-slider__slider .slick-slide .embed-responsive:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition-duration: .33s;
    transition-property: opacity;
    opacity: 0.5;
}
.history-slider__slider .slick-slide.slick-center .embed-responsive:after{
    opacity: 1;
    z-index: -1;
}


.history-slider__slider .slick-slide.slick-center{
    transform: scale(1) rotateY(0);
    z-index: 1;
}
.history-slider__slider .slick-slide.slick-center .js-lazy-img{
    filter: blur(0);
}

.history-slider__slider .slick-slide.slick-center + .slick-slide{
    transform-origin: -10% 50%;
    transform: scale(.75);
}

.history-slider .history-slider__slide-content{
    transition-property: opacity, transform;
    transition-duration: .33s;
    opacity: 0;
    transform: translateY(-150%)
}
.history-slider .slick-slide.slick-center .history-slider__slide-content{
    opacity: 1;
    transform: translateY(0)
}

.history-slider__controls{
    display: flex;
    align-items: center;
    justify-content: center;
}

.history-slider .history-slider__slick-prev{
    position: relative;
}
.history-slider .history-slider__slick-next{
    position: relative;
}


.history-slider__range{
    height: 25px;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
       display: none;
    }
}

.history-slider__range-slide{
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    margin: 0 !important;
}

.history-slider__range-bg{
    position: absolute;
    top: 13px;
    left: 0;
    height: 6px;
    width: 100%;
}

.history-slider__range-bg .progress-bar{
    transition-duration: 0s;
}

.history-slider__slider .slick-slide:not(.slick-current){
    cursor: pointer;
}
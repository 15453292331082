.payone-checkout__payment-methods-line {
  display: flex;
}

.payone-checkout__payment-method-card {
  margin-right: 20px;
  padding: 25px 30px;
  background-color: transparent;
  border: 1px solid lightgray;
  border-radius: 5px;
  cursor: pointer;
}
.payone-checkout__payment-method-card:focus {
  outline: none;
  border-color: var(--color-signature);
  box-shadow: rgba(38, 38, 38, 0.25) 0px 0px 0px 3px;
}
.payone-checkout__payment-method-card:last-child {
  margin-right: 0;
}
.payone-js-checkout__payment-method-card--chosen {
  box-shadow: rgba(38, 38, 38, 0.25) 0px 0px 0px 3px;
}


/** Checkout **/
.payone-checkout {
  font-family: DINNextRoundedLTPro-Light, sans-serif;
  font-size: 18px;
  color: #262626;
  width: 400px;
  background-color: white;
  padding: 13px 31px;
  border-radius: 4px;
  box-shadow: rgba(38, 38, 38, 0.25) 0px 0px 0px 3px;
}

/* Card types line */
.payone-checkout__card-types-line {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}

.payone-checkout__card {
  margin-right: 15px;
  padding: 2px;
}
.payone-checkout__card-visa {
  height: 20px;
}
.payone-checkout__card:last-child {
  margin-right: 0;
}

.payone-js-checkout__card--highlighted {
  outline: 2px solid var(--color-signature);
}

/* Checkout row */
.payone-checkout__row {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.payone-checkout__row:last-child {
  margin-bottom: 0;
}
.payone-checkout__row:only-child {
  margin-bottom: 0;
}

.payone-checkout__fields {
  margin-bottom: 20px;
}

.payone-checkout__row label {
  margin-bottom: 6px;
}

.payone-checkout__row input {
  border: 1px solid lightgray;
  border-radius: 5px;
  font-size: 18px;
  box-sizing: border-box;
  padding: 8px 10px;
}
.payone-checkout__row input:focus {
  border-color: var(--color-signature);
  outline: none;
}

/* Checkout row inner */
.payone-checkout__row-inner {
  display: flex;
  justify-content: space-between;
}

.payone-checkout__row-inner .payone-checkout__col input {
  width: 185px;
}

/* Checkout column */
.payone-checkout__col {
  display: flex;
  flex-direction: column;
}

.payone-checkout__col label {
  font-size: 14px;
}

/* Checkout submit button */
.payone-checkout__submit-btn {
  border: none;
  cursor: pointer;
  padding: 9px 12px;
  background-color: var(--color-signature);
  color: #262626;
  border-radius: 4px;
  font-size: 18px;
}
.payone-checkout__submit-btn:hover {
  background-color: white;
}
.payone-checkout__submit-btn:focus {
  border-color: var(--color-signature);
  outline: none;
  box-shadow: rgba(38, 38, 38, 0.25) 0px 0px 0px 3px;
}
.payone-checkout__submit-btn:disabled {
  cursor: not-allowed;
  opacity: 0.65;
}

/* Checkout select */
.payone-checkout select {
  width: 185px;
  box-sizing: border-box;
  padding: 8px 10px;
  border-radius: 5px;
  font-size: 17px;
  /* Specify this for Firefox */
  border: 1px solid lightgray;
  background-color: rgb(38, 38, 38);
}
.payone-checkout select:focus {
  border: 1px solid var(--color-signature);
}
.payone-checkout select:focus-visible {
  border-color: var(--color-signature);
  outline: none;
}

/* Message container */
.message-container {
  text-align: center;
  margin-top: 7px;
}

/* Related to iframes */
.payone-checkout__fields iframe {
  width: 100%;
  max-height: 39px;
}

.payone-checkout__row-inner .payone-checkout__col section {
  width: 185px;
}

/* Loader */
.lds-ring {
  display: inline-block;
  position: relative;

  /* Commented with and height and added top and left for proper positioning */
  /*width: 80px;*/
  /*height: 80px;*/

  top: -28px;
  left: -26px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  margin: 8px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.custom-checkbox__input:checked ~ .custom-checkbox__box,
.custom-checkbox__input:focus ~ .custom-checkbox__box {
    border-color: var(--color-primary);
}

.custom-checkbox:not(.custom-checkbox--color) .custom-checkbox__input:checked~.custom-checkbox__box:after{
    content: '';
    position: absolute;
    background-color: var(--color-signature);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform: none;

    border: 3px solid white;
}
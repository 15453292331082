@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1743418601913/static/debug/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1743418601913/static/debug/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1743418601913/static/debug/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-0x { font-size: 0.75em; }
.icon-2x { font-size: 1.5em; }
.icon-3x { font-size: 2em; }
.icon-4x { font-size: 2.5em; }
.icon-5x { font-size: 4em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-Abwaehlen:before { content: "\EA01" }
.icon-Auswahl:before { content: "\EA02" }
.icon-Bearbeiten:before { content: "\EA03" }
.icon-Button-nachoben:before { content: "\EA04" }
.icon-Head-Set-17:before { content: "\EA05" }
.icon-Hinzufuegen:before { content: "\EA06" }
.icon-Mehr:before { content: "\EA07" }
.icon-NFC-Authenticate:before { content: "\EA08" }
.icon-NFC-Free-Gift:before { content: "\EA09" }
.icon-NFC-Logo-Icon:before { content: "\EA0A" }
.icon-NFC-Product-Information:before { content: "\EA0B" }
.icon-NFC-Safety:before { content: "\EA0C" }
.icon-NFC-Sustainability:before { content: "\EA0D" }
.icon-Onlinehandel:before { content: "\EA0E" }
.icon-Pfeil-horizontal-oben:before { content: "\EA0F" }
.icon-Pfeil-nachoben:before { content: "\EA10" }
.icon-RFID-Logo:before { content: "\EA11" }
.icon-Repair-Service-17:before { content: "\EA12" }
.icon-Serien:before { content: "\EA13" }
.icon-Uebersicht:before { content: "\EA14" }
.icon-Vergleichen:before { content: "\EA15" }
.icon-VorOrt:before { content: "\EA16" }
.icon-abmelden:before { content: "\EA17" }
.icon-account:before { content: "\EA18" }
.icon-addthis:before { content: "\EA19" }
.icon-anmelden:before { content: "\EA1A" }
.icon-arrow-left:before { content: "\EA1B" }
.icon-arrow-pro-act-test:before { content: "\EA1C" }
.icon-arrow-pro-act:before { content: "\EA1D" }
.icon-arrow-right:before { content: "\EA1E" }
.icon-btnVerfuegbar:before { content: "\EA1F" }
.icon-burger:before { content: "\EA20" }
.icon-buy-local:before { content: "\EA21" }
.icon-calendar-add:before { content: "\EA22" }
.icon-calendar:before { content: "\EA23" }
.icon-cc-available:before { content: "\EA24" }
.icon-cc-info:before { content: "\EA25" }
.icon-check:before { content: "\EA26" }
.icon-circle:before { content: "\EA27" }
.icon-close:before { content: "\EA28" }
.icon-credit_card:before { content: "\EA29" }
.icon-cvc:before { content: "\EA2A" }
.icon-delete:before { content: "\EA2B" }
.icon-deuter-logo-neu:before { content: "\EA2C" }
.icon-edit:before { content: "\EA2D" }
.icon-elements:before { content: "\EA2E" }
.icon-facebook:before { content: "\EA2F" }
.icon-filter:before { content: "\EA30" }
.icon-garantie:before { content: "\EA31" }
.icon-gns-heart:before { content: "\EA32" }
.icon-gonso-arrow-left:before { content: "\EA33" }
.icon-gonso-arrow-right:before { content: "\EA34" }
.icon-gonso-arrow:before { content: "\EA35" }
.icon-gonso-burger:before { content: "\EA36" }
.icon-gonso-close:before { content: "\EA37" }
.icon-gonso-compare:before { content: "\EA38" }
.icon-gonso-search:before { content: "\EA39" }
.icon-halffilledstar:before { content: "\EA3A" }
.icon-heart-filled:before { content: "\EA3B" }
.icon-heart:before { content: "\EA3C" }
.icon-helpcenter-contactform:before { content: "\EA3D" }
.icon-helpcenter-phone:before { content: "\EA3E" }
.icon-helpcenter-whatsapp:before { content: "\EA3F" }
.icon-hose-long:before { content: "\EA40" }
.icon-hose-normal:before { content: "\EA41" }
.icon-hose-short:before { content: "\EA42" }
.icon-infoIcon:before { content: "\EA43" }
.icon-instagram:before { content: "\EA44" }
.icon-klimaneutral-versand:before { content: "\EA45" }
.icon-linkedin:before { content: "\EA46" }
.icon-logout:before { content: "\EA47" }
.icon-maiersports-arrow-right:before { content: "\EA48" }
.icon-maiersports-award:before { content: "\EA49" }
.icon-maiersports-play-btn:before { content: "\EA4A" }
.icon-maiersports-star:before { content: "\EA4B" }
.icon-mail:before { content: "\EA4C" }
.icon-minus:before { content: "\EA4D" }
.icon-new_notification:before { content: "\EA4E" }
.icon-online-shops:before { content: "\EA4F" }
.icon-open:before { content: "\EA50" }
.icon-orders:before { content: "\EA51" }
.icon-ortovox-account:before { content: "\EA52" }
.icon-ortovox-big-arrow:before { content: "\EA53" }
.icon-ortovox-check:before { content: "\EA54" }
.icon-ortovox-close-flyout:before { content: "\EA55" }
.icon-ortovox-compare:before { content: "\EA56" }
.icon-ortovox-de:before { content: "\EA57" }
.icon-ortovox-download:before { content: "\EA58" }
.icon-ortovox-download2:before { content: "\EA59" }
.icon-ortovox-facebook:before { content: "\EA5A" }
.icon-ortovox-info:before { content: "\EA5B" }
.icon-ortovox-instagram:before { content: "\EA5C" }
.icon-ortovox-loeferung:before { content: "\EA5D" }
.icon-ortovox-logo.rectangle:before { content: "\EA5E" }
.icon-ortovox-logout:before { content: "\EA5F" }
.icon-ortovox-repair:before { content: "\EA60" }
.icon-ortovox-search:before { content: "\EA61" }
.icon-ortovox-sheeps-online:before { content: "\EA62" }
.icon-ortovox-signet:before { content: "\EA63" }
.icon-ortovox-star:before { content: "\EA64" }
.icon-ortovox-user-circle:before { content: "\EA65" }
.icon-ortovox-user:before { content: "\EA66" }
.icon-ortovox-versand:before { content: "\EA67" }
.icon-ortovox-ware:before { content: "\EA68" }
.icon-ortovox-warning:before { content: "\EA69" }
.icon-ortovox-youtube:before { content: "\EA6A" }
.icon-ortovox_arrow-left:before { content: "\EA6B" }
.icon-ortovox_arrow-right:before { content: "\EA6C" }
.icon-ortovox_delete:before { content: "\EA6D" }
.icon-ortovox_nav-academy:before { content: "\EA6E" }
.icon-ortovox_nav-account:before { content: "\EA6F" }
.icon-ortovox_nav-activities:before { content: "\EA70" }
.icon-ortovox_nav-all-in:before { content: "\EA71" }
.icon-ortovox_nav-arrow-right:before { content: "\EA72" }
.icon-ortovox_nav-cart:before { content: "\EA73" }
.icon-ortovox_nav-close:before { content: "\EA74" }
.icon-ortovox_nav-contact:before { content: "\EA75" }
.icon-ortovox_nav-external-link:before { content: "\EA76" }
.icon-ortovox_nav-jobs:before { content: "\EA77" }
.icon-ortovox_nav-leaf:before { content: "\EA78" }
.icon-ortovox_nav-logout:before { content: "\EA79" }
.icon-ortovox_nav-neu:before { content: "\EA7A" }
.icon-ortovox_nav-new:before { content: "\EA7B" }
.icon-ortovox_nav-nouveau:before { content: "\EA7C" }
.icon-ortovox_nav-nuovo:before { content: "\EA7D" }
.icon-ortovox_nav-phone:before { content: "\EA7E" }
.icon-ortovox_nav-search-activities:before { content: "\EA7F" }
.icon-ortovox_nav-service:before { content: "\EA80" }
.icon-ortovox_nav-sheep:before { content: "\EA81" }
.icon-ortovox_nav-shop:before { content: "\EA82" }
.icon-ortovox_nav-store-locator:before { content: "\EA83" }
.icon-ortovox_nav-wool:before { content: "\EA84" }
.icon-ortovox_phone:before { content: "\EA85" }
.icon-ortovox_whatsapp:before { content: "\EA86" }
.icon-pause-button:before { content: "\EA87" }
.icon-pause-filled-inverted:before { content: "\EA88" }
.icon-pause-filled:before { content: "\EA89" }
.icon-pinterest:before { content: "\EA8A" }
.icon-play-button:before { content: "\EA8B" }
.icon-play-filled:before { content: "\EA8C" }
.icon-plus:before { content: "\EA8D" }
.icon-poi:before { content: "\EA8E" }
.icon-printer:before { content: "\EA8F" }
.icon-rechnungskauf:before { content: "\EA90" }
.icon-route:before { content: "\EA91" }
.icon-search-thin:before { content: "\EA92" }
.icon-search:before { content: "\EA93" }
.icon-service:before { content: "\EA94" }
.icon-star-empty:before { content: "\EA95" }
.icon-star:before { content: "\EA96" }
.icon-tiktok:before { content: "\EA97" }
.icon-tilegrid-link-arrow:before { content: "\EA98" }
.icon-twitter:before { content: "\EA99" }
.icon-user:before { content: "\EA9A" }
.icon-user2:before { content: "\EA9B" }
.icon-warenkorb:before { content: "\EA9C" }
.icon-warenkorb2:before { content: "\EA9D" }
.icon-whatsapp:before { content: "\EA9E" }
.icon-youtube:before { content: "\EA9F" }


:root {
--icon-Abwaehlen: "\EA01";
    --icon-Auswahl: "\EA02";
    --icon-Bearbeiten: "\EA03";
    --icon-Button-nachoben: "\EA04";
    --icon-Head-Set-17: "\EA05";
    --icon-Hinzufuegen: "\EA06";
    --icon-Mehr: "\EA07";
    --icon-NFC-Authenticate: "\EA08";
    --icon-NFC-Free-Gift: "\EA09";
    --icon-NFC-Logo-Icon: "\EA0A";
    --icon-NFC-Product-Information: "\EA0B";
    --icon-NFC-Safety: "\EA0C";
    --icon-NFC-Sustainability: "\EA0D";
    --icon-Onlinehandel: "\EA0E";
    --icon-Pfeil-horizontal-oben: "\EA0F";
    --icon-Pfeil-nachoben: "\EA10";
    --icon-RFID-Logo: "\EA11";
    --icon-Repair-Service-17: "\EA12";
    --icon-Serien: "\EA13";
    --icon-Uebersicht: "\EA14";
    --icon-Vergleichen: "\EA15";
    --icon-VorOrt: "\EA16";
    --icon-abmelden: "\EA17";
    --icon-account: "\EA18";
    --icon-addthis: "\EA19";
    --icon-anmelden: "\EA1A";
    --icon-arrow-left: "\EA1B";
    --icon-arrow-pro-act-test: "\EA1C";
    --icon-arrow-pro-act: "\EA1D";
    --icon-arrow-right: "\EA1E";
    --icon-btnVerfuegbar: "\EA1F";
    --icon-burger: "\EA20";
    --icon-buy-local: "\EA21";
    --icon-calendar-add: "\EA22";
    --icon-calendar: "\EA23";
    --icon-cc-available: "\EA24";
    --icon-cc-info: "\EA25";
    --icon-check: "\EA26";
    --icon-circle: "\EA27";
    --icon-close: "\EA28";
    --icon-credit_card: "\EA29";
    --icon-cvc: "\EA2A";
    --icon-delete: "\EA2B";
    --icon-deuter-logo-neu: "\EA2C";
    --icon-edit: "\EA2D";
    --icon-elements: "\EA2E";
    --icon-facebook: "\EA2F";
    --icon-filter: "\EA30";
    --icon-garantie: "\EA31";
    --icon-gns-heart: "\EA32";
    --icon-gonso-arrow-left: "\EA33";
    --icon-gonso-arrow-right: "\EA34";
    --icon-gonso-arrow: "\EA35";
    --icon-gonso-burger: "\EA36";
    --icon-gonso-close: "\EA37";
    --icon-gonso-compare: "\EA38";
    --icon-gonso-search: "\EA39";
    --icon-halffilledstar: "\EA3A";
    --icon-heart-filled: "\EA3B";
    --icon-heart: "\EA3C";
    --icon-helpcenter-contactform: "\EA3D";
    --icon-helpcenter-phone: "\EA3E";
    --icon-helpcenter-whatsapp: "\EA3F";
    --icon-hose-long: "\EA40";
    --icon-hose-normal: "\EA41";
    --icon-hose-short: "\EA42";
    --icon-infoIcon: "\EA43";
    --icon-instagram: "\EA44";
    --icon-klimaneutral-versand: "\EA45";
    --icon-linkedin: "\EA46";
    --icon-logout: "\EA47";
    --icon-maiersports-arrow-right: "\EA48";
    --icon-maiersports-award: "\EA49";
    --icon-maiersports-play-btn: "\EA4A";
    --icon-maiersports-star: "\EA4B";
    --icon-mail: "\EA4C";
    --icon-minus: "\EA4D";
    --icon-new_notification: "\EA4E";
    --icon-online-shops: "\EA4F";
    --icon-open: "\EA50";
    --icon-orders: "\EA51";
    --icon-ortovox-account: "\EA52";
    --icon-ortovox-big-arrow: "\EA53";
    --icon-ortovox-check: "\EA54";
    --icon-ortovox-close-flyout: "\EA55";
    --icon-ortovox-compare: "\EA56";
    --icon-ortovox-de: "\EA57";
    --icon-ortovox-download: "\EA58";
    --icon-ortovox-download2: "\EA59";
    --icon-ortovox-facebook: "\EA5A";
    --icon-ortovox-info: "\EA5B";
    --icon-ortovox-instagram: "\EA5C";
    --icon-ortovox-loeferung: "\EA5D";
    --icon-ortovox-logo.rectangle: "\EA5E";
    --icon-ortovox-logout: "\EA5F";
    --icon-ortovox-repair: "\EA60";
    --icon-ortovox-search: "\EA61";
    --icon-ortovox-sheeps-online: "\EA62";
    --icon-ortovox-signet: "\EA63";
    --icon-ortovox-star: "\EA64";
    --icon-ortovox-user-circle: "\EA65";
    --icon-ortovox-user: "\EA66";
    --icon-ortovox-versand: "\EA67";
    --icon-ortovox-ware: "\EA68";
    --icon-ortovox-warning: "\EA69";
    --icon-ortovox-youtube: "\EA6A";
    --icon-ortovox_arrow-left: "\EA6B";
    --icon-ortovox_arrow-right: "\EA6C";
    --icon-ortovox_delete: "\EA6D";
    --icon-ortovox_nav-academy: "\EA6E";
    --icon-ortovox_nav-account: "\EA6F";
    --icon-ortovox_nav-activities: "\EA70";
    --icon-ortovox_nav-all-in: "\EA71";
    --icon-ortovox_nav-arrow-right: "\EA72";
    --icon-ortovox_nav-cart: "\EA73";
    --icon-ortovox_nav-close: "\EA74";
    --icon-ortovox_nav-contact: "\EA75";
    --icon-ortovox_nav-external-link: "\EA76";
    --icon-ortovox_nav-jobs: "\EA77";
    --icon-ortovox_nav-leaf: "\EA78";
    --icon-ortovox_nav-logout: "\EA79";
    --icon-ortovox_nav-neu: "\EA7A";
    --icon-ortovox_nav-new: "\EA7B";
    --icon-ortovox_nav-nouveau: "\EA7C";
    --icon-ortovox_nav-nuovo: "\EA7D";
    --icon-ortovox_nav-phone: "\EA7E";
    --icon-ortovox_nav-search-activities: "\EA7F";
    --icon-ortovox_nav-service: "\EA80";
    --icon-ortovox_nav-sheep: "\EA81";
    --icon-ortovox_nav-shop: "\EA82";
    --icon-ortovox_nav-store-locator: "\EA83";
    --icon-ortovox_nav-wool: "\EA84";
    --icon-ortovox_phone: "\EA85";
    --icon-ortovox_whatsapp: "\EA86";
    --icon-pause-button: "\EA87";
    --icon-pause-filled-inverted: "\EA88";
    --icon-pause-filled: "\EA89";
    --icon-pinterest: "\EA8A";
    --icon-play-button: "\EA8B";
    --icon-play-filled: "\EA8C";
    --icon-plus: "\EA8D";
    --icon-poi: "\EA8E";
    --icon-printer: "\EA8F";
    --icon-rechnungskauf: "\EA90";
    --icon-route: "\EA91";
    --icon-search-thin: "\EA92";
    --icon-search: "\EA93";
    --icon-service: "\EA94";
    --icon-star-empty: "\EA95";
    --icon-star: "\EA96";
    --icon-tiktok: "\EA97";
    --icon-tilegrid-link-arrow: "\EA98";
    --icon-twitter: "\EA99";
    --icon-user: "\EA9A";
    --icon-user2: "\EA9B";
    --icon-warenkorb: "\EA9C";
    --icon-warenkorb2: "\EA9D";
    --icon-whatsapp: "\EA9E";
    --icon-youtube: "\EA9F";
    
}
@media (min-width: 1200px) {
    .modal-xlg {
        width: 90%;
    }
}
@media screen and (min-width: 992px) {
    .modal-md {
        max-width: calc(928rem / 16);
    }
}


.modal--commerceConnector .modal-dialog {
    @media screen and (min-width: 576px) and (max-width: 1200px){
        max-width: 100%
    }
}

.modal--fullscreen .modal-dialog{
    max-width: 100%;
    width: 100%;
    margin: 0;
}

.modal--fullscreen .modal-content{
    border: none;
    box-shadow: none;
    height: 100%;
}

.lang-modal__languages{
    display: none;
}

.lang-modal__languages.is-active{
    display: block;
}

.lang-modal__languages li{
    position: relative;
}
.lang-modal__languages li:before{
    content: '|';
    position: relative;
    left: 0;
    top: 0;
}
.lang-modal__languages li:first-child:before{
    display: none;
}
.modal--lang .modal-body {
    padding: calc(80rem / 16) calc(40rem / 16);
    margin: 0 auto;
    width: 100%;

    @media screen and (min-width: 768px) {
        padding: calc(100rem / 16) calc(30rem / 16);
        max-width: calc(866rem / 16);
    }
}


.modal-xxl {
    width: 100%;
    max-width: initial;
    @media screen and (max-width: 767px){
        margin: 0;
    }
    @media screen and (max-width: 1199px) {
        margin-top: 0;
    }
    @media (min-width: 1200px) {
        width: 90%;
    }
}


.modal-body__title  {
    font-size: calc(24rem / 16);
    line-height: calc(28/24);
    margin-bottom: calc(30rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(50rem / 16);
    }
}
.close.modal-close-lg {
    position: absolute;
    right: calc(40rem / 16);
    top: calc(40rem / 16);
    font-size: calc(20rem / 16);
    color: var(--color-text-default);
    z-index: 1;
}
.modal-body__content + .modal-body__content {
    margin-top: calc(48rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(64rem / 16);
    }
}

.lang-modal__languages a.is-active {
    font-family: var(--font-default-bold);
    font-weight: 400;
}
.compare-list__btn.is-active .compare-list__add{
    display: none;
}
.compare-list__btn.is-active .compare-list__remove{
    display: block;
}

.compare-list__btn:not(.is-active) .compare-list__add{
    display: block;
}
.compare-list__btn:not(.is-active) .compare-list__remove{
    display: none;
}

.compare-list__list-remove{
    position: absolute;
    top:0;
    right: 0;

}
.custom-slider{
    padding: 0 15px;
}

.custom-slider .noUi-horizontal{
    height: 4px;
}

.custom-slider .noUi-handle{
    top: -30px;
    right: -12px !important;
    background-color: var(--color-primary);
    box-shadow: none;
    border-radius: 0;
    border: none;
    height: 24px;
    width: 25px;
}
.custom-slider .noUi-handle:after{
    content: '';
    position: absolute;
    top: 100%;
    left: calc(50% - 4px);
    border-color: var(--color-primary) transparent transparent transparent;
    border-width: 4px 4px 0 4px;
    border-style: solid;
    background-color: transparent;
}
.custom-slider .noUi-handle:before {
    content: none;
}

.custom-slider .noUi-target{
    border: none;
    margin-top: 35px;
}
.custom-slider .noUi-connect{
    background-color: var(--color-primary);
}
.custom-slider .noUi-connects{
    border-radius: 0;
    background-color: var(--color-dark-grey);
}

.custom-slider .noUi-touch-area{
    text-align: center;
    color: #fff;
}
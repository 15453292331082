.pds__product-slider .slick-slider .slick-dots button{
    width: 8px;
    height: 8px;
    padding: 0;
    background-color: var(--color-light-grey);
}

.pds__product-slider .slick-slider .slick-dots .slick-active button{
    background-color: var(--color-signature);
}

.pds__addon-slider__btn{
    width: 40px;
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: var(--color-signature);
    color: var(--color-white);

    border-radius: 0;
}
.btn.pds-slider-main__btns{
    @media screen and (min-width: 768px){
        display: none !important;
    }
}
.slick-next.pds__addon-slider__btn{
    right: calc(-16rem / 16);
}
.slick-prev.pds__addon-slider__btn{
    left: calc(-16rem / 16);
}
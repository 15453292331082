.global-alert{
    position: relative;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
}

.global-alert p:last-of-type {
    margin-bottom: 0;
}

.global-alert a{
    text-decoration: underline;
    color: inherit;
}

.global-alert .icon{
    font-size: .8em;
}

.global-alert button{
    position: absolute;
    top: 0;
    right: 0;
    @media screen and (max-width: 767px) {
        height: 30px;
        width: 30px;
        line-height: 0;
    }
    @media screen and (min-width: 768px) {
        padding-top:13px !important;
    }
}

.global-alert__link{
    @media screen and (min-width: 1500px) {
        margin-right: -.5em;
    }
}
button.accordion{
    align-items: center;
    text-transform: uppercase;
}
button.accordion:hover,
button.accordion:focus{
    text-decoration: none;
}

.accordion .card{
    border: 1px solid #EEEEEE;
    background-color: #ECECEC;
}

.accordion>.card:not(:first-of-type):not(:last-of-type),
.accordion>.card:first-of-type{
    border-bottom: 1px solid #EEEEEE;
}

.accordion .card-header{
    padding-top: calc(16rem / 16);
    padding-left: calc(25rem / 16);
    padding-bottom: calc(16rem / 16);
}

.accordion .card-body{
    padding-top: 0;
    padding-left: calc(25rem / 16);
    padding-right: calc(25rem / 16);
    padding-bottom: calc(31rem / 16);
}

.card-header h5{
    padding-top: 0;
}

.accordion .accordion__icon:before{
    content: var(--icon-Pfeil-nachoben);
    font-size: 6px;
}

.accordion .accordion__icon{
    transform: rotate(0);
    color: var(--color-signature);
    display: flex;
    text-decoration: none !important;
}
.accordion.collapsed .accordion__icon{
    transform: rotate(180deg);
    color: var(--color-primary);
}
.custom-checkbox label{
    cursor: pointer;
    display: block;
}

.custom-checkbox--inline,
.custom-checkbox--inline label{
    white-space: nowrap;
    display: inline-block;
}
.custom-checkbox--inline + .custom-checkbox--inline {
    margin-left:1.25rem;
}
.custom-checkbox:before,
.custom-checkbox:after {
    content: " ";
    display: table;
}.custom-checkbox:after {
     clear: both;
 }
.custom-checkbox__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-checkbox__box {
    position: relative;
    float: left;
    margin-right: calc(7rem/16);
    overflow: hidden;
    border: 1px solid #c4c6c7;
    background: #ffffff;
    width: calc(16rem/16);
    height: calc(16rem/16);
    margin-top: .2em;
}
.custom-checkbox__box:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: calc(7rem/16);
    color: var(--color-primary);
    visibility: hidden;
}
.custom-checkbox__input:checked ~ .custom-checkbox__box:before {
    visibility: visible;
}
.custom-checkbox__input:checked ~ .custom-checkbox__box,
.custom-checkbox__input:focus ~ .custom-checkbox__box {
    border-color: var(--color-primary);
}
.has-error .custom-checkbox__box {
    border-color: var(--color-danger)
}
.custom-checkbox__text {
    display: block;
    overflow: hidden;
}

.custom-checkbox--color{
    display: inline-block;
    width: 48px;
    height: 24px;
}
.custom-checkbox--color label{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.custom-checkbox--color .custom-checkbox__box{
    margin: 0;
    background-color: transparent;
    border: none;
}
.custom-checkbox--color .custom-checkbox__box:before{
    color: #fff;
    text-shadow: 0 1px 5px #000;
}

.custom-checkbox--border label{
    position: relative;
    padding: 15px;
}
.custom-checkbox__outer-box{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-color: #fff;
}
.custom-checkbox__outer-box:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: 1px solid var(--color-text-default);
    pointer-events: none;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    z-index: 1;
}
.custom-checkbox--border .custom-checkbox__input:focus ~ .custom-checkbox__outer-box:after,
.custom-checkbox--border .custom-checkbox__input:checked ~ .custom-checkbox__outer-box:after{
    opacity: 1;
}
.custom-checkbox--image .custom-checkbox__outer-box{
    border-color: var(--color-background-grey);
    @media screen and (max-width: 767px) {
        height: calc(100% + 2px);
    }
}
.form-group.has-error .custom-checkbox--border .custom-checkbox__outer-box,
.has-error .custom-checkbox--border input:focus ~ .custom-checkbox__outer-box,
.has-error .custom-checkbox--border .custom-checkbox__box{
    border-color: var(--color-danger) !important;
}
.custom-checkbox--border:not(.custom-checkbox--image) .custom-checkbox__input:focus ~ .custom-checkbox__box{
    border-color: #c4c6c7;
}

.custom-checkbox--image .custom-checkbox__box{
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 1;
}
.custom-checkbox--image .custom-checkbox__text{
    font-size: 14px;
    position: relative;
}
.custom-checkbox__image {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    flex-shrink: 0;
    flex-basis: 50%;
}





.custom-checkbox--border .custom-checkbox__text,
.custom-radio--border .custom-radio__text{
    margin-bottom: -.125rem;
    position: relative;
}
.custom-radio--border label{
    position: relative;
    padding: 8px 11px;
}
.custom-radio__outer-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-color: #fff;
}
.custom-radio__outer-box:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: 1px solid var(--color-text-default);
    pointer-events: none;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    z-index: 1;
}
.custom-radio--border input:focus ~ .custom-radio__outer-box:after,
.custom-radio--border input:checked ~ .custom-radio__outer-box:after{
    opacity: 1;
}
.form-group.has-error .custom-radio--border .custom-radio__outer-box,
.has-error .custom-radio--border input:focus ~ .custom-radio__outer-box,
.has-error .custom-radio--border .custom-radio__box {
    border-color: var(--color-danger) !important;
}
.custom-radio--border:not(.custom-radio--image) input:focus ~ .custom-radio__box{
    border-color: #c4c6c7;
}
.custom-radio--image .custom-radio__box{
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 1;
}
.custom-radio--image .custom-radio__text{
    font-size: 14px;
    position: relative;
}
.custom-radio__image {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    flex-shrink: 0;
    flex-basis: 50%;
}

.header__submenu.has-bg-img {
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.dropdown-teaser-container ul,
.dropdown-teaser-container ul>li {
    margin:0 !important;
}


@media screen and (max-width: 1024px){
    .header__submenu.has-bg-img {
        background-image: none !important;
    }
    .dropdown-teaser-container {
        margin-top: 1.375rem;
    }
    .dropdown-teaser-container ul>li {
       display: block !important;
    }
    a.dropdown-teaser {
        display: block;
        color:var(--color-primary);
        letter-spacing: 1px;
        height: auto;
        min-height: 64px;
        text-decoration: none;
        padding: 0 1.5rem;
        text-transform: uppercase;
        font-family: var(--font-default-regular);
        font-size: .9375rem;
    }
}

@media screen and (min-width: 1025px){
    .dropdown-teaser-container {
        margin:0 auto;
        text-align: center;
    }
    a.dropdown-teaser {
        position: relative;
        color:#fff;
        text-decoration: none;
        letter-spacing: 2px;
        text-transform: uppercase;
        font-family: var(--font-default-heading);
        text-shadow: 0 0 10px #000000;
        font-size:calc(14rem / 16);
        display: block;
        margin:calc(110rem / 16) 1rem;
        border-bottom:2px solid transparent;
        z-index:10;
    }
    a.dropdown-teaser:hover,
    a.dropdown-teaser:focus {
        color:var(--color-signature);
        border-bottom-color: var(--color-signature);
    }
}


@media screen and (min-width: 1450px){
    a.dropdown-teaser {
        margin:calc(180rem / 16) 2rem;
    }
}
@media screen and (min-width: 1200px){
    a.dropdown-teaser.dropdown-teaser {
        font-size:calc(16rem / 16);
    }
}
.checkout-steps {
    color:var(--color-primary);
    font-family: var(--font-default-bold);
    margin-bottom:4rem;

    @media screen and (max-width: 992px) {
        white-space:nowrap;
        margin-bottom:1.5rem;
        padding-top:2rem;
    }
}
.checkout-steps ol {
    counter-reset: item
}
.checkout-steps .checkout-steps__item{
    position: relative;
    display: inline-block;
    margin:0;
}
.checkout-steps .checkout-steps__item>a,
.checkout-steps .checkout-steps__item>span {
    display: inline-block;
    padding-left:2.5rem;
    position: relative;

    @media screen and (max-width: 767px) {
        text-indent:-19999px;
    }
}
.checkout-steps .checkout-steps__item:before {
    width:2.125rem;
    height:2.125rem;
    line-height: 1.875rem;
    border-radius: 50%;
    border:2px solid var(--color-primary);
    color:var(--color-primary);
    position: absolute;
    top:-.375rem;
    left:0;
    text-align: center;
    display: inline-block;
    content: counters(item, ".") " ";
    counter-increment: item;
    pointer-events: none;
    z-index:1;
}
.checkout-steps .checkout-steps__item--active:before {
    color:#fff;
    background:var(--color-primary);
}
.checkout-steps .checkout-steps__item--complete:before {
    color:var(--color-primary);
    background:var(--color-primary);
}
.checkout-steps .checkout-steps__item--complete>a:before,
.checkout-steps .checkout-steps__item--complete>span:before {
    content:'';
    position: absolute;
    left:.75rem;
    top:0;
    width:.5rem;
    height:.9735rem;
    border:2px solid #fff;
    border-width:0 2px 2px 0;
    transform:rotate(45deg);
    z-index:2;
}

.checkout-steps .checkout-steps__item + .checkout-steps__item {
    margin-left:3.75rem;
}
.checkout-steps .checkout-steps__item + .checkout-steps__item:after {
    content:'';
    display: block;
    position: absolute;
    left:-7.25rem;
    top:.75rem;
    width:3rem;
    height:.125rem;
    background:var(--color-primary);
    margin-left:3.75rem;

    @media screen and (max-width: 767px) {
        left:-7.5rem;
        top:.625rem;
    }
}

.btn{
    font-size: calc(14rem / 16);
    border-width: 1px;
    line-height: 1;
}
.btn.btn:hover,
.btn.btn:focus{
    text-decoration: none;
}

.btn.btn-primary{
    color: var(--color-primary);
    border-color: var(--color-signature);
    background-color: var(--color-signature);
}
.btn.btn-outline-primary{
    color: var(--color-primary);
    border-color: var(--color-signature);
}
.btn.btn-outline-primary:hover,
.btn.btn-outline-primary:focus{
    background-color: var(--color-signature);
}

.btn.btn-outline-primary.text-white {
    color: var(--color-white) !important;
}

.btn.btn-outline-primary:hover,
.btn.btn-outline-primary:focus{
    color: var(--color-primary) !important;
}

.btn.btn-outline-secondary{
    color: var(--color-secondary);
    border-color: var(--color-signature);
}

.btn.btn-outline-secondary:hover,
.btn.btn-outline-secondary:focus{
    background-color: var(--color-signature);
}
.jobs__apply .btn,
.teaser-grid__item .btn,
.btn.btn-lg {
    padding:calc(18rem / 16) calc(25rem / 16);
    @media screen and (min-width: 1200px) {
        padding: calc(16rem / 16) calc(25rem / 16);
    }
}
.teaser-grid__item .btn {
    display: inline-block !important;
    width: auto !important;
}

.btn.btn-unstyled{
    text-decoration: none;
    background-color: initial;
    width: auto !important;
    color: initial;
    font-size: initial;
    height: auto !important;
}
.btn.btn-unstyled:hover,
.btn.btn-unstyled:active,
.btn.btn-unstyled:focus{
    background-color: transparent !important;
    border-color: transparent !important;
    color: initial !important;
}

.btn.btn-signature{
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    color: var(--color-primary);
    background-color: var(--color-signature);
    border-color: var(--color-signature);
    border-width:2px;
}
.btn.btn-signature:hover,
.btn.btn-signature:active,
.btn.btn-signature:focus{
    border-color: var(--color-primary);
    color: var(--color-primary);
    background-color: var(--color-white);
}

/*Text Helper*/
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.overflow-visible{
    overflow: visible;
}

/*Text Color Helper*/
.text-white { color: #ffffff; }
.text-color-default { color: var(--color-text-default); }

/*Flex Helper*/
.flex-1{
    flex: 1 0 0px;
    flex-basis: auto;
}
.flex-md-1{
    @media screen and (min-width: 768px) {
        flex: 1 0 0px;
        flex-basis: auto;
    }
}

/*List Helper*/
.flex-pipe-list{
    position: relative;
    overflow: hidden;
}
.flex-pipe-list ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: -1px;
}
.flex-pipe-list li {
    flex-grow: 1;
    flex-basis: auto;
    margin: .25em 0;
    padding: 0 0.5em;
    text-align: center;
    border-left: 1px solid #ccc;
}

.bg-primary--transparent{
    background-color: var(--color-primary-transparency);
}
.bg-grey--transparent{
    background-color: var(--color-grey-transparency);
}
.bg-white--transparent{
    background-color: rgba(255,255,255,.5);
}

.bg-white--grid-hero{
    background-color: rgba(255,255,255,.9);
}

.p-relative{
    position: relative;
}
.p-initial{
    position: initial;
}

.pointer-none{
    pointer-events: none;
}
.pointer-auto{
    pointer-events: auto;
}

.z-index-0 {
    z-index: 0;
}
.z-index-1 {
    z-index: 1;
}
.z-index-2 {
    z-index: 2;
}
.z-index-auto {
    z-index: auto;
}
.z-index-auto--force {
    z-index: auto !important;
}
.js-go-to-link{
    cursor: pointer;
}

/* font sizes */
.fs1 {
    font-size: 1rem !important;
}
.fs0-875 {
    font-size: .875rem !important;
}
.fs0-75 {
    font-size: .75rem !important;
}
.fs1-125 {
    font-size: 1.125rem !important;
}
.fs1-25 {
    font-size: 1.25rem !important;
}
.fs1-5 {
    font-size: 1.5rem !important;
}
.fs2 {
    font-size: 2rem !important;
}
.fs2-5 {
    font-size: 2.5rem !important;
}

@media screen and (max-width: 767px) {
    .xs-pos-sticky-bottom {
        position: sticky;
        bottom:0;
        left:0;
        right:0;
        margin: 0;
        z-index: 1;
        max-width: 100%;
    }
    .xs-pos-sticky-bottom>.btn {
        display: block;
    }
}

.box-shadow-hover{
    position: relative;
}
.box-shadow-hover:after{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    box-shadow: 0 2px 24px 0 rgba(38,38,38,0.25);
    transition-property: opacity;
    transition-duration: .33s;
    pointer-events: none;
}
.box-shadow-hover:hover:after{
    opacity: 1;
}
.box-shadow{
    position: relative;
}
.box-shadow:after{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    box-shadow: 0 2px 24px 0 rgba(38,38,38,0.25);
    transition-property: opacity;
    transition-duration: .33s;
    pointer-events: none;
}

.w-md-auto{
    @media screen and (min-width: 768px) {
        width: auto !important;
    }
}

.faded{
    opacity: .33;
}

.border.border--2{
    border-width: 2px !important;
}

.ui-datepicker{
    transform: translateY(-190px);
}

.flex-no-wrap{
    flex-wrap: nowrap;
}

.ui-datepicker{
    z-index: 2 !important;
}

.word-break-all{
    word-break: break-all;
}

.word-break-word{
    word-break: break-word;
}

.mw-50{
    min-width: 50%;
}

.mw-33{
    min-width: 33%;
}

.w-md-100{
    @media screen and (min-width: 768px) {
        width: 100% !important;
    }
}

.w-100-mobile{
    @media screen and (max-width: 767px) {
        width: 100% !important;
    }
}

.tablet-h3{
    @media screen and (max-width: 1200px){
        font-size: calc(32rem/16);
    }
}
.mobile-h3{
    @media screen and (max-width: 767px){
        font-size: calc(20rem/16);
    }
}

.tt-suggestion.tt-selectable img{
    min-width: 45px;
    min-height: 60px;
}

.img-gradient:before{
    @media screen and (max-width: 1200px){
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        background: #dadada;
        opacity: 0.2;
    }
}

.password-verdict{
    font-family: var(--font-default-bold);
    color: #000;
}

/** disable google maps fullscreen button on ios due to errors */
@supports (-webkit-overflow-scrolling: touch) {
    .gm-fullscreen-control {
        display: none;
    }
}

.position-initial{
    position: initial;
}

.h-md-100{
    @media screen and (min-width: 768px){
        height: 100%;
    }
}

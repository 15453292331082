.text-initial {
    text-transform: initial;
}

.sizetable-btn {
    @media screen and (max-width: 767px) {
        height: auto !important;
        margin-top: calc(16rem / 16) !important;
    }
    @media screen and (min-width: 768px) {
        text-transform: uppercase;
    }

    background-color: #EEEEEE;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: calc(48rem / 16);
}

.sizetable-btn.sizetable-btn__modal {
    background-color: var(--color-white);
    color: #5F5652;
    border: 1px solid #C0BAB8;
}

.sizetable-btn .sizetable-btn__icon {
    font-size: 6px;
}

.sizetable-btn.sizetable-btn--link {
    background-color: #ECECEC;
}

.size-cut__spacing {
    margin: calc(64rem / 16) 0;
    @media screen and (max-width: 767px) {
        margin: calc(32rem / 16) 0;
    }
}

.size-cut__item {
    width: 60px;
}

.size-cut__item-icon {
    width: 60px;
    height: 60px;
}

.size-cut__item:hover,
.size-cut__item:active {
    cursor: pointer;
    color: var(--color-signature) !important;
}

.size-cut__item:hover .size-cut__item-icon,
.size-cut__item:active .size-cut__item-icon,
.size-cut.nav-tabs .nav-link.active .size-cut__item-icon {
    border-color: var(--color-signature) !important;
}

.size-cut.nav-tabs .nav-link.active {
    background-color: transparent;
    color: var(--color-signature);
}

.modal-sizeSelect .modal-header,
.modal-sizeSelect .modal-body {
    padding-left: calc(40rem / 16);
    padding-right: calc(40rem / 16);

    align-items: center;
}

.size-checkbox {
    margin: 0 calc(-16rem / 16) calc(12rem / 16);

    @media screen and (max-width: 767px) {
        margin: 0 calc(-16rem / 16) calc(8rem / 16);
    }
}

.size-checkbox__item {
    min-width: calc(61rem / 16);
    height: calc(40rem / 16);
    margin: 0 calc(16rem / 16) calc(24rem / 16);
}


.size-checkbox .custom-radio__input:checked ~ .custom-radio__box-btn {
    background-color: var(--color-white);
    border-color: var(--color-signature) !important;
    color: #5f5652;
}

.size-checkbox .custom-radio__box-btn {
    height: calc(40rem / 16);
    display: flex;
    align-items: center;
    justify-content: center;

    border-color: #dee2e6 !important;
    color: #5f5652;
    font-family: var(--font-default-light);
    font-size: calc(15rem / 16);
    padding: 0;
}

.size-checkbox .custom-radio__box-btn:hover,
.size-checkbox .custom-radio__box-btn:active,
.size-checkbox .custom-radio__box-btn:focus {
    color: #5f5652 !important;
    border-color: var(--color-signature) !important;
}

.size-checkbox label {
    width: 100%;
}

.size-checkbox .disabled .custom-radio__box-btn,
.size-checkbox .disabled .custom-radio__input:checked ~ .custom-radio__box-btn {
    background-color: var(--color-light-grey);
    color: var(--color-text-grey);
}

.size-checkbox .disabled .custom-radio__box-btn:hover,
.size-checkbox .disabled .custom-radio__box-btn:active,
.size-checkbox .disabled .custom-radio__box-btn:focus,
.size-checkbox .disabled .custom-radio__input:checked ~ .custom-radio__box-btn {
    color: var(--color-text-grey) !important;
    border-color: #dee2e6 !important;
}

.size-checkbox .disabled,
.size-checkbox .disabled label,
.size-checkbox .disabled span,
.size-checkbox .disabled span:not(:disabled):not(.disabled)
{
    cursor: not-allowed;
}

.outtra-modal-trigger-button {
    font-family: DINNextRoundedLTPro-Medium,sans-serif;
}

.pds-quicklinks .outtra-modal-trigger-button {
    font-size: .875rem;
    letter-spacing: 2px;
    font-family: DINNextRoundedLTPro-Medium,sans-serif;
    color: #262626 !important;
    text-transform: uppercase !important;
    margin-bottom: 0;
    background-color: white;
    border: 0;
    padding: 0;
    border-radius: 0;
    height: 0;
    text-align: left;
    line-height: .875rem;
}

.pds-quicklinks .outtra-modal-trigger-button:hover {
    padding: 0;
    height: 0;
    letter-spacing: 2px;
    border: 0;
}

.pds-quicklinks .outtra-modal-trigger-button:focus {
    outline: 0;
}

/* ----- MODAL STYLE ----- */
@media screen and (min-width: 768px) {
    .modal.modal-sizeselect .modal-dialog {
        position: fixed;
        margin: auto;
        width: calc(420rem / 16);
        height: 100%;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
    }

    .modal.modal-sizeselect .modal-content {
        height: 100%;
        overflow-y: auto;
    }

    .modal.modal-sizeselect.fade .modal-dialog {
        right: 0;
        -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
        -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
        -o-transition: opacity 0.3s linear, right 0.3s ease-out;
        transition: opacity 0.3s linear, right 0.3s ease-out;
    }

    .modal.modal-sizeselect.fade.in .modal-dialog {
        right: 0;
    }
}

.modal-content {
    border-radius: 0;
    border: none;
}

.modal-header {
    border-bottom-color: #EEEEEE;
    background-color: #FAFAFA;
}


.pds-video-btn {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    pointer-events: none;
}

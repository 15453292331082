.customer-backend__card {
    display: block;
    text-align: center;
    background-color: #fff;
    border-radius: 5px;
}

.customer-backend__tabs{
    display: flex;
}

.customer-backend__tab{
    color: var(--color-primary);
    background-color: #fff;
    margin-right: 1rem;
    flex-grow: 1;
    max-width: 100%;
    text-align: center;
    @media screen and (max-width: 767px) {
        margin-right: 0;
        border-bottom: 3px solid transparent;
    }
}

.customer-backend__tab:last-child{
    margin-right: 0;
}

.customer-backend__tab a, .customer-backend__tab > div{
    width: 100%;
    height: 100%;
    padding: .75rem 1.75rem;
    display: flex;
    align-items: center;
    @media screen and (max-width: 767px) {
        padding: .75rem .25rem;
        flex-direction: column;
    }
}

.customer-backend__tab span{
    align-items: center;
    width: 100%;
    font-family: var(--font-default-heading-medium);
    @media screen and (max-width: 767px) {
        margin-top: .5rem;
        font-size: .75em;
        word-break: break-all;
        font-family: var(--font-default);
    }
}

.customer-backend__tab--home{
    flex-grow: 0;
    @media screen and (max-width: 767px) {
        flex-grow: 1;
        max-width: 100%;
        flex-basis: 0;
    }
}

.customer-backend__tab.customer-backend__tab--home a, .customer-backend__tab.customer-backend__tab--home div{
    padding: .75rem 1.25rem;
}

.customer-backend__tab.is-active{
    position: relative;
    background-color: var(--color-primary);
    color: #fff;
    @media screen and (max-width: 767px) {
        background-color: #fff;
        color: var(--color-primary);
        border-bottom-color: var(--color-primary);
    }
}

.customer-backend__spacer{
    width: 100%;
    height: 3px;
    background-color: var(--color-light-grey);
}

.customer-backend__orders-articles,
.customer-backend__orders-address{
    @media screen and (min-width: 768px) {
        display: block !important;
    }
}

.customer-backend__order-state {
    @media screen and (min-width: 768px) {
        flex-basis: 20%;
    }
}

.customer-backend__orders{
    @media screen and (max-width: 767px) {
        display: block !important;
    }
}

.btn.btn-outline-primary.customer-order-mobile-collapse:not(.collapsed){
    background-color: var(--color-primary) !important;
    color: white;
}

.customer-backend__backlink-size {
    font-size: calc(10rem /16) !important;
}

.customer-backend__blank-link:target:before {
    content: "";
    display: block;
    height: calc(var(--header-height) + 50px); /*50px are needed for proper offset because deuter logo is higher than header*/
    margin-top: calc(calc(var(--header-height) * -1) - 50px);
    pointer-events: none;
    background: transparent;
    opacity: 0;
    @media screen and (max-width: 991px) {
        height: calc(var(--header-height-mobile) + 50px);
        margin-top: calc(calc(var(--header-height-mobile) * -1) - 50px);
    }
}

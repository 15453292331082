.js-cart-dropdown__container {
    padding-left: 1em;
    padding-right: 1em;
    overflow-y: auto;
}

.js-cart-dropdown__container h5.text-uppercase.text-primary.mr-1.mb-0 {
    font-family: DINNextRoundedLTPro-Medium, sans-serif;
    font-weight: 400;
}

.js-cart-dropdown__container div.col span.strong.text-uppercase, .js-cart-dropdown__container div.d-flex.justify-content-between.align-items-end.pb-2 div.d-flex.flex-column small.mb-2 {
    display: none;
}

.js-cart-dropdown__container h4.text-primary.text-center.py-3.mb-0.ml-3 {
    text-align: left !important;
    width: 100%;
    margin-left: 0 !important
}

.js-cart-dropdown__container div.mt-2.mx-md-5.mb-4 {
    margin: 10px 0 !important;
}

.js-cart-dropdown__container div.mt-2.mx-md-5.mb-4 a.btn {
    display: inline-block;
    float: right;
    width: 46% !important;
    padding: 1rem;
}

.js-cart-dropdown__container div.mt-2.mx-md-5.mb-4 a.btn.btn-outline-primary {
    float: left;
}

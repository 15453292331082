.alert-notification--fixed {
    overflow: initial;
    pointer-events: none;
}
.alert-notification .alert{
    border-radius: 0;
    background-color: #fff;
    border-width: 0;
    box-shadow: 0 0 14px 0 rgba(0,0,0,.15);
    pointer-events: auto;
}
.number-spinner{
    display: flex;
    height: 24px;
}

.number-spinner--dark .number-spinner__btn, .number-spinner--dark .number-spinner__input{
    background-color: var(--color-light-grey);
}

.number-spinner__btn{
    width: 24px;
    height: 24px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-primary);
    background-color: #fff;
    font-size: .8em;
}

.number-spinner__input{
    height: 24px;
    border: none;
    background-color: #fff;
    color: var(--color-primary);
    width: auto;
    text-align: center;
    max-width: 50px;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    margin: 0;
}

.number-spinner__input::-webkit-inner-spin-button,
.number-spinner__input::-webkit-outer-spin-button {
    margin: 0;
    -webkit-appearance: none;
}

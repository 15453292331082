.simple-dropdown__btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem .75rem;
    font-family: var(--font-default-bold);
    border: 1px solid var(--color-primary);
    background: #fff;
}

.simple-dropdown__content{
    color: var(--color-primary);
    background-color: #fff;
    border: 1px solid var(--color-primary);
    border-top: transparent;
}

.simple-dropdown__btn:not(.collapsed) .icon{
    transform: rotate(90deg);
}
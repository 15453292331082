.full-width-slider__text-container{
    z-index: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    position: relative;
    @media screen and (max-width: 767px) {
        position: absolute;
        top: 0;
        left:0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        width: 100%;
        height: 100%;
    }
}

.full-width-slider .slick-dots{
    transform: translateY(-43px);
    z-index: 5;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    @media screen and (max-width: 767px){
        transform: translateY(-24px);
    }
}

.full-width-slider.slick-slider li button{
    visibility: visible;
    font-size: 0;
    width: calc(8rem / 16);
    height: calc(8rem / 16);
    border: none;
    padding: 0;
    margin: 0 calc(5rem / 16);
}
.full-width-slider.slick-slider li.slick-active button{
    background-color: var(--color-signature);
}

.full-width-slider__video{
    z-index: 10;

    @media screen and (max-width: 1100px){
        right: calc(40rem / 16);
    }
}

.full-width-slider__prev{
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: calc(10rem / 16);

    @media screen and (max-width: 991px) {
        left: calc(-10rem / 16);
    }
}
.full-width-slider__next{
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: calc(10rem / 16);

    @media screen and (max-width: 991px) {
        right: calc(-10rem / 16);
    }
}

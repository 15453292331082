.magazine-list__subline{
    font-size: calc(16rem / 16);
}
.magazine-list__headline{
    font-size: calc(48rem / 16);
}

.magazine-list__navigation{
    text-transform: uppercase;
    font-size: calc(16rem / 16);
    font-family: var(--font-default-heading);
    margin: calc(32rem / 16) 0;

    @media screen and (min-width: 768px) {
        margin: calc(48rem / 16) 0;
    }
}
.magazine-list__navigation a.active,
.magazine-list__navigation a:hover,
.magazine-list__navigation a:focus,
.magazine-list__navigation a:active{
    border-bottom: 2px solid var(--color-signature);
}


.magazine__pagination{
    margin: calc(32rem / 16) 0;
}
.magazine__pagination .active{
    color: var(--color-signature);
}
.magazine__pagination-arrow .page-link{
    font-size: calc(9rem / 16);
}
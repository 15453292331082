.hero-slider__dots{
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
}
.hero-slider__dots li{
    margin: 0;
}
.hero-slider__dots li button{
    visibility: visible;
    font-size: 0;
    width: .5rem;
    height: .5rem;
    border: none;
    padding: 0;
    border-radius: 100%;
    margin: 0 .3125rem;
}

.hero-slider__dots li.slick-active button{
    background-color: var(--color-signature);
    transform: scale(1);
}

.hero-slider h2.h1{
    color: #fff
}
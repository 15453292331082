.product-grid__filter-bar__category{
    margin-left: calc(10rem / 16);
}

@media screen and (max-width: 767px) {
    .product-grid__form{
        padding-right:0 !important
    }
}

.product-grid__form .custom-radio__box-btn:hover,
.product-grid__form .custom-radio__box-btn:focus {
    color:#fff;
    background:var(--color-signature);
    border-color:var(--color-signature);
}

.product-grid__form .custom-checkbox__text{
    font-family:var(--font-default-regular);
}
.product-grid__form .custom-checkbox__box{
    border-radius: 0;
}
.product-grid__form .custom-checkbox:not(.custom-checkbox--color) .custom-checkbox__input:checked~.custom-checkbox__box:after{
    border: 3px solid white;
    border-radius: 0%;
}

@media screen and (max-width: 767px){
    .btn.product-grid__filter-collapse {
        width: 95% !important; /* override btn-unstyled */
    }
}
@media screen and (min-width: 768px){
    .product-grid__filter-collapse .icon {
        transform: rotate(90deg);
    }
    .product-grid__filter-collapse.show .icon {
        transform: rotate(-90deg);
    }
}
.product-grid__filter-collapse .icon-arrow-right:before {
    color:var(--color-signature);
    content:var(--icon-ortovox_arrow-right);
}

.product-grid__legend{
    font-size: calc(16rem / 16);
    font-family: var(--font-default-bold);
    display: flex;
    align-items: center;
}

.product-grid-item__text-overlay .btn.disabled{
    background-color: #F0F0F0;
    color: #666;
    border: 1px solid var(--color-grey);
}

a.btn.product-grid-item__size.disabled{
    pointer-events: initial;
    cursor: initial;
}

a.btn.product-grid-item__size { /*additional media query for making sure all sizes are displayed in 1 line*/
    @media screen and (min-width: 1200px) and (max-width: 1451px){
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }
}

.form-control.product-grid__select{
    background-position: right center;
    padding-right: 1rem;
    font-family: var(--font-default-bold);

    @media screen and (max-width: 991px){
        font-size:.75rem;
    }
}

.form-control.product-grid__select,
.form-control.product-grid__select option {
    text-align: right;
}

.form-control.product-grid__select.product-grid__select-sorting{
    direction: rtl;
    width: auto;
    max-width: 100%;
}

.form-control.product-grid__select.product-grid__select-sorting option {
    direction: ltr;
}

.product-grid-item__rating .product-rating{
    margin-bottom: 0;
    display: flex;
    flex-wrap: nowrap;
}

.product-grid-item__rating .product-rating span{
    white-space: nowrap;
}

.product-grid-item__rating{
    font-size: calc(12rem / 16);
}

.btn.product-grid__remove-input{
    @media screen and (min-width: 768px) {
        font-size: .85rem;
        line-height: .85rem;
        padding: 1.0625rem 1.5rem .9375rem;
        font-family: var(--font-default-bold);
        text-transform: uppercase;
    }
}

.product-grid__category-bar{
    @media screen and (min-width: 768px) {
        margin-top: 27px !important;
    }
}

.product-grid__filter-collapse-target > h5 {
    text-transform: none;
    padding-bottom: 6px;
}

.product-grid__filter-collapse-target > h5:first-child {
    margin-top: 5px;
}
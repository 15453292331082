.checkout-sidebar {
    position: relative;
    background: #fff;
    padding:1.625rem 1.375rem 1.375rem 1.375rem;
    @media screen and (min-width: 1200px) {
        margin-left: -2rem;
    }
}

.checkout-sidebar__hl {
    font-size:1.125rem;
    padding-bottom:1.625rem;
    font-family: var(--font-default-heading-medium);
    border-bottom:2px solid var(--color-light-grey);
}
.checkout-sidebar__item {
    position: relative;
    padding:.625rem 0;
    font-family: var(--font-default-heading-medium);
    border-bottom:2px solid var(--color-light-grey);
}
.checkout-sidebar__bottom {
    padding-top:.5rem;
}
.checkout-sidebar__bottom a:not(.btn) {
    text-decoration: underline;
}
.checkout-sidebar__bottom a:not(.btn):hover,
.checkout-sidebar__bottom a:not(.btn):focus {
    text-decoration: none;
}
.wysiwyg p,
.wysiwyg ol,
.wysiwyg ul{
    margin-bottom: 1rem;
}
.wysiwyg ol,
.wysiwyg ul{
    padding-left:1rem;
}

.wysiwyg-author{
    margin-top: calc(50rem / 16);

    @media screen and (max-width: 767px){
        margin-top: calc(25rem / 16);
    }
}

.wysiwyg-author__img{
    border-radius: 50%;
}

.wysiwyg-author__text{
    font-family: var(--font-light);
    padding-left: calc(16rem / 16);
    font-size: calc(15rem / 16);
}

.wysiwyg a:not(.btn){
    color:var(--color-primary);
    text-decoration: underline;
}
.wysiwyg a:not(.btn):hover,
.wysiwyg a:not(.btn):focus{
    color:var(--color-signature);
    text-decoration: underline;
}

.formbuilder__heading{
    font-size: calc(32rem / 16);
}

.form-errors ul li:before{
    content: none;
}

.form-control{
    height: calc(48rem / 16);
    border-color: #C0BAB8;
}

.form-control.form-control--border-btm {
    border-width:0 0 1px 0;
    box-shadow:none;
}

.form-group {
    position: relative;
}

.form-group.has-error .form-control{
    border-color: #CC0033;
}

.form-errors{
    color: #CC0033;
    font-size: calc(11rem / 16);
}

.form-group.has-error .form-control {
    background-image: none;
}

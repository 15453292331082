.hero-grid--btn{
    margin-top: calc(32rem / 16);
    @media screen and (max-width: 991px){
        margin-top: calc(16rem / 16);
    }
}

.hero-grid--noimg {
    padding-top: 2rem;
}
.hero-grid__body{
    color: #fff;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform:translateY(-50%);
}
.hero-grid__headline {
    color:#fff;
    margin: 0;
    padding: 0;
}

.hero-grid__box {
    @media screen and (min-width: 768px) {
        background-color: rgba(255, 255, 255, .9);
        padding-right: 3rem;
    }
}
.hero-grid__text {
    font-family: var(--font-serif);
}
.hero-grid__text:not(:first-child) {
    margin-top: .5rem;
}

html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
    background-color: var(--color-body-bg);
}
body {
    position: relative;
    background-color: var(--color-body-bg);
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
}
.container.container--narrow {
    max-width: calc(960rem/16);
    width: 100%;
}
.bg-grey{
    background-color: var(--color-body-bg);
}
.bg-black{
    background-color: #000;
}
.text-rating{
    color: var(--rating-color);
}
.bg-rating{
    background-color: var(--rating-color);
}


.bg-temp-hot {
    background-color: var(--color-temp-hot)
}
.bg-temp-normal {
    background-color: var(--color-temp-normal)
}
.bg-temp-cold {
    background-color: var(--color-temp-cold)
}

.page-content--has-header{
    margin-top: var(--header-height);
    @media screen and (max-width: 1024px) {
        margin-top: var(--header-height-mobile);
    }
}
.page-content--has-header--checkout {
    @media screen and (max-width: 1024px) {
        margin-top: calc(var(--header-height-mobile) + 20px);
    }
}


.page-content--has-header ~ .popover {
    top: calc(var(--header-height) * -1) !important;
    @media screen and (max-width: 1024px) {
        top: calc(var(--header-height-mobile) * -1) !important;
    }
}
@supports (-webkit-touch-callout: none) {
    .page-content--has-header ~ .popover {
        top: calc(calc(var(--header-height) * -1) - var(--header-height)) !important;
        @media screen and (max-width: 1024px) {
            top: calc(calc(var(--header-height-mobile) * -1) - var(--header-height-mobile)) !important;
        }
    }
}
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) {
    .page-content--has-header ~ .popover {
        top: calc(calc(var(--header-height) * -1) - var(--header-height)) !important;
        @media screen and (max-width: 1024px) {
            top: calc(calc(var(--header-height-mobile) * -1) - var(--header-height-mobile)) !important;
        }
    }
}}

.popover--close{
    position: absolute;
    right: 0;
    top: 0;
}


@media screen and (max-width: 767px) {
    .page-content--has-header.xs-fake-overlay {
        margin: 0;
        padding:0 0 3rem 0;
        z-index: 2000;
        position: relative;
        background: #fff;
    }
    .xs-fake-overlay ~ *,
    .xs-fake-overlay + * {
        display: none;
    }
}

.col-pg-filter{
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
    @media screen and (min-width: 1440px) {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    @media screen and (min-width: 768px) {
        flex: 0 0 25%;
        max-width: 25%;
    }
}

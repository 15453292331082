
.form-box {
    background: var(--color-background-grey);
    padding: calc(20rem / 16);
    padding-top: calc(40rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(60rem / 16) calc(20rem / 16);
    }
}
.form-box__content {
    @media screen and (min-width: 768px){
        max-width: calc(724rem / 16);
        margin-left: auto;
        margin-right: auto;
    }
}
.btn{
    letter-spacing: 0.1em;
    font-family: var(--font-default-heading-medium);
    box-shadow: none;
    text-decoration:none;
}

.btn.btn-lg {
    padding:.75rem 1.5rem;
    @media screen and (min-width: 1200px) {
        padding:1rem 3.5rem;
    }
}

.btn.btn-sm {
    padding: .3rem 1rem;
}
.btn:not(:disabled):not(.disabled).active, .btn:not(:disabled):not(.disabled):active {
    box-shadow: none;
}

.btn.btn-md {
    padding:.5rem 1.5rem;
    @media screen and (min-width: 1200px) {
        padding:.75rem 2rem;
    }
}

.btn.btn-voucher-remove.btn-voucher-remove {
    padding:.625rem 1.5rem !important;
}

.btn.btn-sm {
    padding: .3rem 1rem;
}

.btn-icon{
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.btn-unstyled{

}
.btn-unstyled:hover, .btn-unstyled:active, .btn-unstyled:focus {
    color: inherit;
    box-shadow: inherit !important;
}

.btn-outline-primary, .btn-outline-secondary{
    border-width: 2px;
}

.btn-primary {
    color:#fff;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active {
    background:var(--color-primary);
}

.btn-white{
    color: #212529;
    background-color: #fff;
    border-color: #fff;
    box-shadow: inset 0 1px 0 hsla(0,0%,100%,.15), 0 1px 1px rgba(0,0,0,.075);
}
.btn-white:hover{
    color: var(--color-primary);
    background-color: #ececec;
    border-color: #e6e6e6;
}
.btn-white:not(:disabled):not(.disabled).active, .btn-white:not(:disabled):not(.disabled):active, .show>.btn-white.dropdown-toggle{
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf;
}

.btn-outline-white{
    color: #fff;
    border-color: #fff;
    border-width: 2px;
}
.btn-outline-white:hover{
    background-color: #fff;
    color: var(--color-primary);
}
.btn-outline-white:not(:disabled):not(.disabled).active, .btn-outline-white:not(:disabled):not(.disabled):active, .show>.btn-outline-white.dropdown-toggle{
    background-color: #fff;
    color: #000;
}

.btn.btn-outline-secondary:hover, .btn.btn-outline-secondary:focus, .btn.btn-outline-secondary:active{
    color: var(--color-primary);
}

.btn-secondary{
    color: var(--color-primary);
}
.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active {
    color: var(--color-primary);
}

.btn-grey{
    background-color: var(--color-light-grey);
    color: var(--color-primary);
}
.btn-grey[disabled]{
    color: var(--color-middle-grey)
}


.btn--global-alert{
    padding: 1rem 1.5rem;
    font-size: .85rem;
    line-height: .85rem;
    padding-top: calc(20rem/16);
    padding-bottom: calc(15rem/16);
}


.btn-lightbox-play{
    width: 50px;
    height: 50px;
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-shadow: 0px 0px 6px #515151;
}

.commerceConnector__buttons #buyLocallyButton.disabled,
.commerceConnector__buttons #buyLocallyButton:disabled {
    opacity: 0.0;
}
/*Increased specificity to override bootstrap; This is needed in case the libs.css finished loading before the style.css */
.d-desktop-header-none.d-desktop-header-none{
    @media all and (min-width: 1025px){
        display: none !important;
    }
}
.d-desktop-header-flex.d-desktop-header-flex{
    @media all and (min-width: 1025px){
        display: flex !important;
    }
}
.d-desktop-header-inline.d-desktop-header-inline{
    @media all and (min-width: 1025px){
        display: inline !important;
    }
}
.d-desktop-header-block.d-desktop-header-block{
    @media all and (min-width: 1025px){
        display: block !important;
    }
}


.justify-content-desktop-header-start.justify-content-desktop-header-start {
    @media all and (min-width: 1025px){
        justify-content: flex-start!important;
    }
}


.mt-desktop-header-0.mt-desktop-header-0{
    @media all and (min-width: 1025px){
        margin-top: 0 !important;
    }
}
.mb-desktop-header-0.mb-desktop-header-0{
    @media all and (min-width: 1025px){
        margin-bottom: 0 !important;
    }
}
.mx-desktop-header-0.mx-desktop-header-0{
    @media all and (min-width: 1025px){
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

.m-desktop-header-n1.m-desktop-header-n1 {
    @media all and (min-width: 1025px){
        margin: -.25rem!important;
    }
}

.px-desktop-header-0.px-desktop-header-0{
    @media all and (min-width: 1025px){
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
.p-desktop-header-0.p-desktop-header-0{
    @media all and (min-width: 1025px){
        padding: 0 !important;
    }
}
.pr-desktop-header-2.pr-desktop-header-2{
    @media all and (min-width: 1025px){
        padding-right: .5rem!important;
    }
}
.pr-desktop-header-3.pr-desktop-header-3{
    @media all and (min-width: 1025px){
        padding-right: 1rem!important;
    }
}
.pb-desktop-header-3.pb-desktop-header-3{
    @media all and (min-width: 1025px){
        padding-bottom: 1rem!important;
    }
}
.px-desktop-header-3.px-desktop-header-3{
    @media all and (min-width: 1025px){
        padding-left: 1rem!important;
        padding-right: 1rem!important;
    }
}
.px-desktop-header-2.px-desktop-header-2{
    @media all and (min-width: 1025px){
        padding-left: .5rem!important;
        padding-right: .5rem!important;
    }
}
.pl-desktop-header-0.pl-desktop-header-0{
    @media all and (min-width: 1025px){
        padding-left: 0!important;
    }
}


.col-desktop-header-9.col-desktop-header-9{
    @media all and (min-width: 1025px){
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;

        width: 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
}
.col-desktop-header-8.col-desktop-header-8{
    @media all and (min-width: 1025px){
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;

        width: 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
}
.col-desktop-header-6.col-desktop-header-6{
    @media all and (min-width: 1025px){
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;

        width: 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
}
.col-desktop-header-4.col-desktop-header-4{
    @media all and (min-width: 1025px){
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;

        width: 33.3333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
}
.col-desktop-header-3.col-desktop-header-3{
    @media all and (min-width: 1025px){
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;

        width: 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
}
.col-desktop-header-2.col-desktop-header-2{
    @media all and (min-width: 1025px){
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;

        width: 16.66666%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
}
.col-desktop-header.col-desktop-header {
    @media all and (min-width: 1025px){
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
}


.offset-desktop-header-1.offset-desktop-header-1{
    @media all and (min-width: 1025px){
        margin-left:8.333333%
    }
}


.flex-desktop-header-row.flex-desktop-header-row {
    @media all and (min-width: 1025px){
        flex-direction: row!important;
    }
}

@import './../libs/photoswipe/dist/photoswipe';

.lg-pdsLightbox{
    display: flex;
    justify-content: center;
    align-items: center;
}

.lg-pdsLightbox .lg {
    background: #fff;
    color: var(--color-primary);
    position: relative;
    @media screen and (min-width: 992px) {
        width: 80% !important;
        height: 95% !important;
        max-width: 1550px;
    }
}

.lg-pdsLightbox .lg-toolbar.lg-group, .lg-pdsLightbox .lg-icon{
    color: var(--color-primary);
}
.lg-pdsLightbox #lg-zoom-in,.lg-pdsLightbox #lg-zoom-out,.lg-pdsLightbox #lg-actual-size,.lg-pdsLightbox .lg-close{
    background: rgba(255,255,255,.33);
}

.lg-pdsLightbox .lg-icon:hover{
    color: #000;
}

.lg-pdsLightbox .lg-close.lg-icon{
    color: var(--color-primary)
}

.lg-pdsLightbox .lg-toogle-thumb.lg-icon{
    color: #fff;
}

.lg-pdsLightbox .lg-sub-html{
    position: absolute;
    left: 0;
}

.lg-pdsLightbox .lg-actions .lg-next,.lg-pdsLightbox .lg-actions .lg-prev{
    color: var(--color-primary);
}

.lg-pdsLightbox.lg-outer .lg-thumb-outer{
    background: rgba(255,255,255,.3);
    border-top: 1px solid #000;
    padding: 0 10px;
}
.lg-pdsLightbox.lg-outer .lg-thumb-item{
    border-radius: 0;
    border-color: var(--color-grey);
    background: #fff;
    border-width: 1px;
}
.lg-pdsLightbox.lg-outer .lg-thumb-item.active, .lg-pdsLightbox.lg-outer .lg-thumb-item:hover{
    border-color: var(--color-primary);
}


.lg-pdsLightbox .lg-sub-html{
    display: none;
}

.lg-pdsLightbox.lg-outer .lg-toogle-thumb:after{
    font-family: 'iconfont';
    content: var(--icon-Pfeil-nachoben);
    font-size: 0.5em;
    transform: rotate(0deg);
    transition-duration: .33s;
    transition-property: transform;
    display: block;
}

.lg-pdsLightbox.lg-outer.lg-thumb-open .lg-toogle-thumb:after{
    transform: rotate(180deg);
}

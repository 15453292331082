@font-face {
    font-family: "PPMori-Extralight";
    font-style: normal;
    font-stretch: normal;
    font-weight: 400;
    font-display: swap;
    src: local("PPMori-Extralight"),
    url("/static/fonts/PPMori-Extralight.woff2") format("woff2"),
    url("/static/fonts/PPMori-Extralight.woff") format("woff");
}

@font-face {
    font-family: "PPMori-Extralight";
    font-style: italic;
    font-stretch: normal;
    font-weight: 400;
    font-display: swap;
    src: local("PPMori-Extralight"),
    url("/static/fonts/PPMori-ExtralightItalic.woff2") format("woff2"),
    url("/static/fonts/PPMori-ExtralightItalic.woff") format("woff");
}

@font-face {
    font-family: "PPMori-Regular";
    font-style: normal;
    font-stretch: normal;
    font-weight: 400;
    font-display: swap;
    src: local("PPMori-Regular"),
    url("/static/fonts/PPMori-Regular.woff2") format("woff2"),
    url("/static/fonts/PPMori-Regular.woff") format("woff");
}

@font-face {
    font-family: "PPMori-Regular";
    font-style: italic;
    font-stretch: normal;
    font-weight: 400;
    font-display: swap;
    src: local("PPMori-Regular"),
    url("/static/fonts/PPMori-RegularItalic.woff2") format("woff2"),
    url("/static/fonts/PPMori-RegularItalic.woff") format("woff");
}

@font-face {
    font-family: "PPMori-SemiBold";
    font-style: normal;
    font-stretch: normal;
    font-weight: 400;
    font-display: swap;
    src: local("PPMori-SemiBold"),
    url("/static/fonts/PPMori-SemiBold.woff2") format("woff2"),
    url("/static/fonts/PPMori-SemiBold.woff") format("woff");
}

@font-face {
    font-family: "PPMori-SemiBold";
    font-style: italic;
    font-stretch: normal;
    font-weight: 400;
    font-display: swap;
    src: local("PPMori-SemiBold"),
    url("/static/fonts/PPMori-SemiBoldItalic.woff2") format("woff2"),
    url("/static/fonts/PPMori-SemiBoldItalic.woff") format("woff");
}

@font-face {
    font-family: "utopiastd-regular";
    font-style: normal;
    font-stretch: normal;
    font-weight: 400;
    font-display: swap;
    src: local("utopiastd-regular"),
    url("/static/fonts/utopiastd-regular.woff2") format("woff2"),
    url("/static/fonts/utopiastd-regular.woff") format("woff");
}

@font-face {
    font-family: "utopiastd-bold";
    font-style: normal;
    font-stretch: normal;
    font-weight: 400;
    font-display: swap;
    src: local("utopiastd-bold"),
    url("/static/fonts/utopiastd-bold.woff2") format("woff2"),
    url("/static/fonts/utopiastd-bold.woff") format("woff");
}

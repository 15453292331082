.custom-file__name{
    position: absolute;
    top: 0;
    left: 0;
    padding-left: .75em;
    overflow: hidden;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    white-space: nowrap;
}

.custom-file-label:after{
    content: attr(data-translate)
}
.custom-file-label{
    border-color: #a1a1a1;
    position: relative;
    background-color: transparent;
}

.custom-file-input{
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
}

.custom-file .floating-label{
    background-color: transparent;
}

.custom-file{
    display: block;
    background-color: #fff;
}


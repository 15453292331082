.pds-headline{
    @media screen and (max-width: 767px){
        font-size: calc(24rem / 16);
        letter-spacing: initial;
    }
}

.pds-subline{
    font-size: calc(20rem / 16);
    font-family: var(--font-default-heading);

    @media screen and (max-width: 767px){
        font-size: calc(16rem / 16);
    }
}

.awards-headline{
    font-size: calc(32rem / 16);

    margin-bottom: calc(16rem / 16);

    @media screen and (min-width: 768px){
        margin-bottom: calc(32rem / 16);
    }
}

.pds-quicklinks{
    font-size: calc(14rem / 16);
    letter-spacing: 2px;
}

.pds-product-description__headline{
    font-size: calc(32rem / 16);

    @media screen and (max-width: 767px){
        font-size: calc(20rem / 16);
    }
}

.pds-product-description__subline{
    font-family: var(--font-default-heading);

    font-size: calc(20rem / 16);

    @media screen and (max-width: 767px){
        font-size: calc(16rem / 16);
    }
}

.pds__color-select:after,
.pds__color-select.pds__color-select--active:before{
    content: none;
}

.pds__color-select{
    position: relative;
    width: calc(32rem / 16);
    height: calc(32rem / 16);
    border-radius: 50%;
    margin-right: calc(32rem / 16);
    margin-top: calc(32rem / 16);
}
.pds__color-select__link.pds__color-select--active:before,
.pds__color-select__link:hover:before,
.pds__color-select__link:focus:before{
    display: block;
    content:'';
    position: absolute;
    left:1px;
    top:1px;
    border: 1px solid #fff;
    width: calc(30rem / 16);
    height: calc(30rem / 16);
    border-radius: 50%;

    @media screen and (max-width: 767px){
        width: calc(38rem / 16);
        height: calc(38rem / 16);
    }
}
.pds__color-select__color{
    border-radius: 50%;
}

.pds__color-table{
    margin-bottom: calc(19rem / 16);
}
.pds__color-text{
    font-size: calc(16rem / 16);
    color: var(--color-primary);
}

.pds-color--spacing{
    @media screen and (min-width: 768px){
        margin-bottom: calc(64rem / 16);
    }
}
.range-of-use__container{
    border-radius: 4px;
    overflow: hidden;
}
.range-of-use{
    height: 8px;
    width: calc(100% / 5);
    background-color: #ececec;
}
.range-of-use--active .border-right {
    border-right: 1px solid #fff !important;
    margin: 2px 0;
    height: calc(100% - 4px);
}
.range-of-use--active {
    background: var(--color-signature);
}
.range-of-use--active-last {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.range-of-use:not(.range-of-use--active) .border-right{
    border-right: 1px solid #817672 !important;
    margin: 2px 0;
    height: calc(100% - 4px);
}
.range-of-use--active-last .border-right,
.range-of-use:last-child .border-right{
    display: none;
}

.range-of-use__container-text{
    color: var(--color-default);
    word-break: normal;
    letter-spacing: 2px;
}

.range-of-use__container-rating{
    margin-bottom: calc(18rem / 16);

    @media screen and (max-width: 767px){
        margin-bottom: calc(16rem / 16);
    }
}

.range-of-use__headline{
    margin-bottom: calc(34rem / 16);
    @media screen and (max-width: 767px){
        margin-bottom: calc(16rem / 16);
    }
}

.rate-product .custom-control-inline{
    padding: 0;
    margin-right: 4px;
}

.custom-radio .custom-control-label:before {
    content: none;
}

.custom-radio .custom-control-label:after {
    content: none;
}

.pds__product-slider-thumb .slick-current .pds__product-slider-thumb-image{
    border-color: var(--color-signature);
}

.pds__product-slider-thumb{
    width: 102px;
}

.pds__product-slider-main{
    width: 100%;
    padding: 0 calc(88rem / 16);
    @media screen and (max-width: 767px){
        padding: 0 calc(24rem / 16);
    }
    @media screen and (min-width: 768px) {
        width: calc(100% - 102px);
        padding: 0 0.75rem;
    }
    @media (min-width: 1200px){
        padding: 0 2.5rem;
    }
    @media (min-width: 1440px){
        padding: 0 5rem;
    }
}

.pds__color-image{
    width: 60px;
    height: 86px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: calc(21rem / 16);
    margin-bottom: calc(16rem / 16);

    @media screen and (max-width: 767px) {
        width: 55px;
        height: 61px;

        margin-right: calc(5rem / 16);
        margin-left: calc(5rem / 16);
    }
}

.pds__color-image.pds__color-image--active:before{
    content: none;
}
.pds__color-image.pds__color-image--active:after{
    background-color: var(--color-signature);
}
.pds__color-image:after{
    background-color: transparent;
    height: 1px;
    width: 32px;

    margin: 0 14px;
}

.additional-info__list{
    padding-left: 0;
}
.additional-info__list li{
    list-style: none;
    display: flex;
    align-items: start;
    margin-bottom: calc(7rem / 16);
}
.additional-info__list li:before{
    content: '';
    width: 4px;
    min-width: 4px;
    max-width: 4px;
    height: 4px;
    min-height: 4px;
    max-height: 4px;
    transform: translateY(8px);

    background-color: var(--color-signature);
    display: block;
    border-radius: 50%;
    margin-right: 4px;
}

.pds-data__spacing{
    padding: calc(24rem / 16) calc(35rem / 16) calc(29rem / 16) calc(24rem / 16);

    @media screen and (max-width: 767px){
        padding: calc(16rem / 16) calc(16rem / 16) calc(24rem / 16) calc(16rem / 16);
    }
}

.pds-data-img--small-spacing > div{
    margin-bottom: calc(17rem / 16);
}

.pds-data__headline{
    font-size: calc(24rem / 16);
    word-break: normal;

    @media screen and (max-width: 767px){
        font-size: calc(18rem / 16);
    }
}
.pds-data__subline{
    margin-bottom: calc(21rem / 16);

    @media screen and (max-width: 767px){
        margin-bottom: calc(8rem / 16);
    }
}

.pds-data__list{
    margin-bottom: calc(38rem / 16);
}

.pds-data__table{
    word-break: normal;
}

.pds-data__table th{
    color: var(--color-primary);
}

.pds-data__table td,
.pds-data__table th{
    vertical-align: baseline;
}

.award-headline{
    font-size: calc(24rem / 16);
    margin-bottom: calc(12rem / 16);

    @media screen and (max-width: 767px){
        font-size: calc(16rem / 16);
        margin-bottom: calc(8rem / 16);
    }
}

.award-subline{
    color: var(--color-default);

    font-size: calc(16rem / 16);
    margin-bottom: calc(24rem / 16);

    @media screen and (max-width: 767px){
        font-size: calc(12rem / 16);
        margin-bottom: calc(16rem / 16);
    }
}

.pds__color-image:hover:before{
    content: none;
}
.pds__color-image:hover:after{
    background-color: var(--color-signature);
}

.pds-product-price{
    margin-top: calc(32rem / 16);
    margin-bottom: 0;

    @media screen and (max-width: 767px){
        margin-top: calc(8rem / 16);
        margin-bottom: calc(24rem / 16);
    }
}

.pds-price{
    font-size: calc(24rem / 16);

    @media screen and (max-width: 767px){
        font-size: calc(20rem / 16);
        letter-spacing: 1px;
    }
}

.pds-table__th{
    word-break: normal;
}
.pds-table__td{
    word-break: normal;
}

.pds__product{
    padding-top: calc(63rem / 16);

    @media screen and (max-width: 767px){
        padding-top: calc(11rem / 16);
    }
}


.outtra-btn--spacing{
    margin-top: calc(16rem / 16);
    margin-bottom: calc(16rem / 16);
}

.outtra-gonso-button {
    width: 100%;
    text-transform: uppercase;
    color: #262626;
    background-color: var(--color-signature);
    border: solid;
    border-color: var(--color-signature);
    border-width: 2px;
    cursor: pointer;
    position: relative;
    font-size: 14px;
    font-weight: 400;
    height: 50px;
    line-height: 47px;
    text-align: center;
    transition: all 0.2s ease-in-out;
    letter-spacing: .1em;
    font-family: var(--font-default-bold);
}

.outtra-gonso-button:hover {
    color: #262626;
    background-color: #fff;
    border-color: var(--color-signature);
}

.outtra-gonso-button.inverse {
    color: #262626;
    background-color: #fff;
}

.outtra-gonso-button.inverse:hover {
    color: #262626;
    background-color: var(--color-signature);
}


.product-badge{
    position: absolute;
    top: calc(16rem / 16);
    left: 0;
    z-index: 1;


}

.product-badge__item{
    padding: 10px 7px;
    font-size: calc(12rem / 16);
    background-color: var(--color-signature);
    color: var(--color-primary);
    text-transform: uppercase;
}

.pds-highlight__spacing{
    padding: calc(24rem / 16);

    @media screen and (max-width: 767px){
        padding: calc(16rem / 16);
    }
}

.table--compare th{
    background-color: transparent;
    white-space: nowrap;
}

.compare-product__container{
    padding-top: .5rem !important;
}

.table--compare .btn{
    white-space: nowrap;
}

table.table--checkout thead {
    background-color: transparent;
    color: #000;
    font-size: .8em;
}
.video-js.vjs-default-skin--hidden .vjs-big-play-button{
    display: none;
}

.video-js__playbtn{
    height: 106px;
    width: 106px;
    border-radius: 100%;
    border: none;
    background-color: #fff;
    color: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: initial;
    @media screen and (max-width: 767px) {
        height: 56px;
        width: 56px;
    }
}

.video-js__playbtn:after{
    margin-left: 10px;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 19px 0 19px 33px;
    border-color: transparent transparent transparent var(--color-primary);
    display: inline-block;
    @media screen and (max-width: 767px) {
        margin-left: 4px;
        border-width: 8px 0 8px 16px;
    }
}

.video-js__playbtn:focus,
.video-js__playbtn{
    background-color: var(--color-primary);
    color: #fff;
}

.video-js__playbtn:focus:after,
.video-js__playbtn:after {
    border-color: transparent transparent transparent #fff;
}

.video-js__textcontainer{
    opacity: 1;
    transition-duration: .75s;
    transition-property: opacity;
}

.vjs-default-skin--hidden.vjs-controls-disabled + .video-js__textcontainer,
.vjs-default-skin--hidden.vjs-error + .video-js__textcontainer,
.vjs-default-skin--hidden.vjs-has-started + .video-js__textcontainer,
.pimcore_editable_video.is-playing + .video-js__textcontainer{
    opacity: 0;
    visibility: hidden;
}

.pimcore_editable_video.is-playing + .video-js__textcontainer--toggle-play-pause-btns,
.vjs-default-skin--hidden.vjs-ended + .video-js__textcontainer,
.vjs-default-skin--hidden.vjs-has-started.vjs-paused + .video-js__textcontainer{
    opacity: 1;
    visibility: visible;
}

.vjs-default-skin .vjs-big-play-button{
    position: absolute;
    top: calc(50% - 33px);
    left: calc(50% - 45px);
}

.vjs-poster.vjs-poster {
    background-size: cover;
}

.video-js__textcontainer--toggle-play-pause-btns .video-js__pausebtn,
.pimcore_editable_video.is-playing + .video-js__textcontainer .video-js__playbtn {
    display: none;
}

.video-js__textcontainer--toggle-play-pause-btns .video-js__playbtn,
.pimcore_editable_video.is-playing + .video-js__textcontainer .video-js__pausebtn {
    display: block;
}
